import React from 'react';
import { TDashboardFilter } from '../../../api/dashbboards/DashboardFilter';
import FilterModal from '../../dataframes/build-dataframe/FilterModal';
import { DragItem } from '../../../types/dataframes/dataframe-drag-item';

export type EditDashboardFilter = { name: string; filter: TDashboardFilter };

type Props = {
	dashboardFilter: EditDashboardFilter;
	saveDashboardFilter: (dashboardFilter: TDashboardFilter) => void;
	setModalOpen: (value: boolean) => void;
	isOpen?: boolean;
	readonly?: boolean;
};

const EditFilterModal = (props: Props) => {
	const { dashboardFilter, isOpen, setModalOpen, saveDashboardFilter, readonly } = props;

	const beforeClose = () => {
		setModalOpen(false);
	};

	const beforeSave = (filter: DragItem) => {
		dashboardFilter.filter.excluded = filter.excluded ?? false;
		dashboardFilter.filter.isExistingValue = filter.isExistingValue ?? false;
		dashboardFilter.filter.operator = filter.operator;
		// dashboardFilter.filter.sequence = filter.
		dashboardFilter.filter.value = filter.value;

		saveDashboardFilter(dashboardFilter.filter);
		setModalOpen(false);
	};

	return (
		<>
			{isOpen && (
				<FilterModal
					filter={{
						id: dashboardFilter.filter.entity_id ?? 0,
						type: dashboardFilter.filter.entity_type,
						title: dashboardFilter.name,
						excluded: dashboardFilter.filter.excluded,
						isExistingValue: dashboardFilter.filter.isExistingValue,
						operator: dashboardFilter.filter.operator,
						value: dashboardFilter.filter.value,
					}}
					handleClose={beforeClose}
					handleSave={beforeSave}
					readonly={readonly}
				/>
			)}
		</>
	);
};

export default EditFilterModal;
