import {
	Button,
	Card,
	CardBody,
	Flex,
	FlexItem,
	Grid,
	GridItem,
	Text,
	Title,
} from '@patternfly/react-core';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import './Category.scss';
import { CategoryQuestion, DiscoverCategory, DiscoverContext } from '../../types/discover';
import EmptyResults from '../../components/EmptyResults';
import { Question as QuestionApi } from '../../api/questions/Questions';
import { iconMap } from '../../helpers/global-icon-map.helper';
import { QuestionCategory } from '../../api/question-category/QuestionCategory';
import { useMount } from 'react-use';
import Question from './Question';
import Loader from '../../components/util/Loader';
import DatePeriodSelector from '../../components/date-period-selector/DatePeriodSelector';
import { TNewDateRange } from '../../api/types/TNewDateRange';

type Props = {
	pageContext: DiscoverContext;
};

const Category = (props: Props) => {
	const { pageContext } = props;
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const id = searchParams.get('id');
	const tag = searchParams.get('tag');
	const term = searchParams.get('term');
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [categories, setCategories] = useState<DiscoverCategory[]>([]);
	const [selectedDate, setSelectedDate] = useState<TNewDateRange>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle=""
					pageDescription=""
					subNavContext={undefined}
				/>
			),
			sidebarContext: null,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	useMount(() => {
		setIsLoading(true);
		setCategories([]);
		if (parseInt(id ?? '0') > 0) {
			void QuestionCategory.Get(parseInt(id!), 0).then((response) => {
				const newCategory: DiscoverCategory = {
					icon: iconMap[response.icon],
					id: parseInt(id!),
					key: `Category_${response.title}`,
					name: response.title,
					questions: [],
				};
				setCategories((prevCategories) => [...prevCategories, newCategory]);

				void QuestionApi.GetAllByCategoryId(id!).then((questionResponse) => {
					questionResponse = questionResponse.filter((x) => x.category == parseInt(id!));

					questionResponse.map((question) => {
						const newCatQuestion: CategoryQuestion = {
							key: `Question_${question.name}`,
							question: question.name,
							description: question.description,
							priority: question.sequence,
							tags: [],
							hasChart: true,
							chartId: question.chart.toString(),
						};
						newCategory.questions.push(newCatQuestion);
					});
					setIsLoading(false);
				});
			});
		} else if (tag) {
			void QuestionCategory.GetPermitted().then((response) => {
				response.map((category) => {
					const newCategory: DiscoverCategory = {
						icon: iconMap[category.icon],
						id: category.id!,
						key: `Category_${category.title}`,
						name: category.title,
						questions: [],
					};

					if (!categories.includes(newCategory)) {
						setCategories((prevCategories) => [...prevCategories, newCategory]);
					}

					void QuestionApi.GetAllByCategoryId(category.id!.toString(), ['tags']).then(
						(questionResponse) => {
							questionResponse = questionResponse.filter(
								(x) => x.category == category.id
							);

							questionResponse.map((question) => {
								const newCatQuestion: CategoryQuestion = {
									key: `Question_${question.name}`,
									question: question.name,
									description: question.description,
									priority: question.sequence,
									tags: question.tags ? question.tags.map((x) => x.name) : [],
									hasChart: true,
									chartId: question.chart.toString(),
								};

								if (
									newCatQuestion.tags.length > 0 &&
									newCatQuestion.tags.some(
										(x) => x.toLowerCase() == tag.toLowerCase()
									)
								) {
									newCategory.questions.push(newCatQuestion);
								}
								setTimeout(() => {
									setIsLoading(false);
								}, 500);
							});
						}
					);
				});
			});
		} else if (term) {
			void QuestionCategory.GetPermitted().then((response) => {
				response.map((category) => {
					const newCategory: DiscoverCategory = {
						icon: iconMap[category.icon],
						id: category.id!,
						key: `Category_${category.title}`,
						name: category.title,
						questions: [],
					};

					if (!categories.includes(newCategory)) {
						setCategories((prevCategories) => [...prevCategories, newCategory]);
					}

					void QuestionApi.GetAllByCategoryId(category.id!.toString(), ['tags']).then(
						(questionResponse) => {
							questionResponse = questionResponse.filter(
								(x) => x.category == category.id
							);

							questionResponse.map((question) => {
								const newCatQuestion: CategoryQuestion = {
									key: `Question_${question.name}`,
									question: question.name,
									description: question.description,
									priority: question.sequence,
									tags: question.tags ? question.tags.map((x) => x.name) : [],
									hasChart: true,
									chartId: question.chart.toString(),
								};

								if (
									newCatQuestion.tags.length > 0 &&
									newCatQuestion.tags.some((x) =>
										x.toLowerCase().includes(term.toLowerCase())
									)
								) {
									newCategory.questions.push(newCatQuestion);
								}
								setTimeout(() => {
									setIsLoading(false);
								}, 500);
							});
						}
					);
				});
			});
		}
	});

	if (isLoading) {
		return (
			<Card>
				<CardBody>
					<Loader />
				</CardBody>
			</Card>
		);
	}

	if (categories && categories.length <= 0 && !isLoading) {
		return (
			<EmptyResults
				emptyStateBody="No results match the discover criteria. Head back to discover something different."
				backComponent={
					<Button
						variant="link"
						onClick={() => {
							navigate(-1);
						}}
					>
						<Flex alignItems={{ default: 'alignItemsCenter' }}>
							<FlexItem>
								<FontAwesomeIcon icon={faArrowLeft} />
							</FlexItem>
							<FlexItem>
								<Text>Discover</Text>
							</FlexItem>
						</Flex>
					</Button>
				}
			/>
		);
	}

	return (
		<Card>
			<CardBody>
				<Grid
					hasGutter
					className="categories"
				>
					{!isLoading &&
						categories &&
						categories.map((category) => {
							{
								if (category.questions.length == 0) {
									return;
								}
							}
							return (
								<>
									<Flex>
										<Text>Filters:</Text>
										<div className="filter-container">
											<div className="date-selector-container">
												<DatePeriodSelector
													applyDateRange={setSelectedDate}
												/>
											</div>
										</div>
									</Flex>
									<Grid hasGutter>
										<GridItem span={12}>
											<Flex
												alignItems={{ default: 'alignItemsCenter' }}
												className="category-title"
											>
												<FlexItem>
													<FontAwesomeIcon
														icon={category?.icon}
														size="2x"
													/>
												</FlexItem>
												<FlexItem>
													<Title
														headingLevel="h3"
														data-testid={`category-${category.key}`}
													>
														{category?.name}
													</Title>
												</FlexItem>
											</Flex>
										</GridItem>
										<Grid span={12}>
											{category?.questions &&
												category?.questions.length > 0 &&
												category?.questions.map((q: CategoryQuestion) => {
													return (
														<Question
															question={q}
															selectedDate={selectedDate}
														/>
													);
												})}
										</Grid>
									</Grid>
								</>
							);
						})}
				</Grid>
			</CardBody>
		</Card>
	);
};

export default Category;
