import { TWidget } from '../dashbboards/DashboardWidgets';

export type TPresentationSlide = {
	id?: number;
	page: number;
	presentation?: TPresentationSlide;
	widgets: TWidget[] | number[];
};

export class PresentationSlide implements TPresentationSlide {
	id?: number;
	page: number;
	presentation?: TPresentationSlide;
	widgets: TWidget[] | number[];

	constructor(presentationSlide: TPresentationSlide) {
		this.id = presentationSlide.id;
		this.page = presentationSlide.page;
		this.presentation = presentationSlide.presentation;
		this.widgets = presentationSlide.widgets;
	}

	public static Default(): TPresentationSlide {
		return {
			page: 0,
			widgets: [],
		};
	}
}
