import {
	AlertVariant,
	Button,
	Card,
	CardBody,
	Flex,
	FormGroup,
	Grid,
	GridItem,
	TextInput,
} from '@patternfly/react-core';
import React, { useCallback, useState } from 'react';
import PresentationPageList from './PresentationPageList';
import { PresentionationContext } from '../../types/presentation';
import { useOutletContext, useParams } from 'react-router';
import { GridLayoutOutletContext } from '../../layout/Layout';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { TWidget, Widget } from '../../api/dashbboards/DashboardWidgets';
import PresentationSlide from './PresentationSlide';
import { useMount } from 'react-use';
import { Folder, TFolder } from '../../api/foundational-elements/Folder';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import SelectFolderDropdown from '../../helpers/helper-components/SelectFolderDropdown';
import { Present } from '../../api/present/Present';
import { addNewRecentPresentation } from '../../helpers/helper-components/recents-factory-helper';
import DashboardFilters from '../analyze/dashboard/DashboardFilters';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { DashboardFilter } from '../../api/dashbboards/DashboardFilter';
import { TPresentationSlide } from '../../api/present/PresentationSlide';

interface Props {
	pageContext: PresentionationContext;
}

const PresentationDetail = (props: Props) => {
	const { pageContext } = props;
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { presentationId } = useParams();
	const { addToast } = useToast();
	const { gridLayout } = useOutletContext<GridLayoutOutletContext>();
	const [selectedSlide, setSelectedSlide] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	// const [presentationModel, setPresentationModel] = useState<Present>({ ...Present.Default() });
	const presentationModel: Present = Present.Default();
	// if (presentationId) {
	// 	presentationModel =
	// 		pageContext.presentations.find((p) => p.id === parseInt(presentationId)) ??
	// 		presentationModel;
	// }
	const [currentSlide, setCurrentSlide] = useState<TPresentationSlide>(
		presentationModel.presentationSlides[selectedSlide]
	);
	const [pageModel, setPageModel] = useState<Present>(presentationModel);
	const [folders, setFolders] = useState<TFolder[]>([]);
	// const [widget, setWidget] = useState<Widget>();
	const [widgets, setWidgets] = useState<Widget[]>([]);
	const [unSavedChanges, setUnSavedChanges] = useState(false);
	const [existingPresentationName, setExistingPresentationName] = useState<string>();
	const [selectedDate, setSelectedDate] = useState<TNewDateRange>();
	const [dashboardFilters, setDashboardFilters] = useState<DashboardFilter[] | undefined>([]);
	const [dashboardFiltersToDelete, setDashboardFiltersToDelete] = useState<
		DashboardFilter[] | undefined
	>([]);

	const updateSelectedDate = useCallback(
		(startDateRange: TNewDateRange, endDateRange: TNewDateRange) => {
			if (setSelectedDate) {
				setSelectedDate({
					begin_date: startDateRange.begin_date,
					end_date: endDateRange.end_date,
					period: startDateRange.period,
					sequence: startDateRange.sequence,
				});
			}
		},
		[selectedDate, setSelectedDate]
	);

	useMount(() => {
		Folder.GetAll({ type: 'presentations' })
			.then((response) => {
				if (response) {
					setFolders(response.filter((f) => f.type === 'presentations'));
				}
			})
			.catch(() => {
				addToast('Get folders failed.', AlertVariant.danger);
			});
		if (presentationId) {
			setLoading(true);
			Present.Get(parseInt(presentationId), ['presentationSlides'])
				.then((presentationResponse) => {
					if (presentationResponse) {
						// setPresentationModel({ ...presentationResponse });
						const newModel = { ...presentationModel, ...presentationResponse };

						const promiseAll: Promise<TPresentationSlide>[] = [];
						presentationResponse.presentationSlides.forEach((slide) => {
							if (typeof slide != 'number' && slide.id)
								promiseAll.push(
									Present.GetPresentationSlide(Number(slide.id), ['widgets'])
								);
						});

						Promise.all(promiseAll)
							.then((responses: TPresentationSlide[]) => {
								responses.forEach((slideResponse) => {
									const presentSlide = newModel.presentationSlides.find(
										(slide) => slide.id === slideResponse.id
									) as TPresentationSlide;
									presentSlide.widgets = (slideResponse.widgets as TWidget[]).map(
										(w) => {
											return { ...w, presentationSlide: presentSlide.id };
										}
									);
								});
								setPageModel(newModel);
								setExistingPresentationName(presentationResponse.name);
								setCurrentSlide({
									...newModel.presentationSlides[0],
								});
							})
							.catch((_) => {
								addToast('Failed to get Presentation Slides');
							});
					}
				})
				.catch(() => {
					addToast('Get presentation failed.', AlertVariant.danger);
				});

			//log entry into recents for loaded presentation
			addNewRecentPresentation(presentationId);
		}
	});

	const updateModel = useCallback(
		(widgets: Widget[]) => {
			pageModel.presentationSlides[selectedSlide].widgets = widgets;
			setPageModel(pageModel);
		},
		[pageModel, setPageModel, selectedSlide]
	);

	const addNewPage = useCallback(() => {
		pageModel.presentationSlides.push({
			widgets: [],
			page: pageModel.presentationSlides.length,
		});
		setPageModel(pageModel);
		setSelectedSlide(pageModel.presentationSlides.length - 1);
		setCurrentSlide({
			...pageModel.presentationSlides[pageModel.presentationSlides.length - 1],
		});
	}, [pageModel, setPageModel, selectedSlide, setSelectedSlide]);

	const removePage = useCallback(
		(index: number) => {
			pageModel.presentationSlides.splice(index, 1);
			if (selectedSlide + 1 === index && index > 1) {
				setSelectedSlide(index - 1);
				setCurrentSlide({ ...pageModel.presentationSlides[index - 1] });
			} else {
				setSelectedSlide(0);
				setCurrentSlide({ ...pageModel.presentationSlides[0] });
			}
			setPageModel(pageModel);
		},
		[pageModel, setPageModel, selectedSlide, setSelectedSlide]
	);

	const updateSelectedPage = useCallback(
		(slideNumber: number) => {
			setSelectedSlide(slideNumber);
			setCurrentSlide({ ...pageModel.presentationSlides[slideNumber] });
		},
		[pageModel, setPageModel, selectedSlide, setSelectedSlide, setCurrentSlide]
	);

	const handlePresentationWidgetChange = useCallback(
		(widget: Widget) => {
			!unSavedChanges && setUnSavedChanges(true);
			widget.page = selectedSlide;

			const newPageModel = { ...pageModel };
			if (widget.isNew && !widget.widgetChanged) {
				widget.id = newPageModel.presentationSlides[selectedSlide].widgets.length + 1;
				newPageModel.presentationSlides[selectedSlide].widgets = [
					...(newPageModel.presentationSlides[selectedSlide].widgets as Widget[]),
					widget,
				];
			} else {
				const newWidgets = [
					...(newPageModel.presentationSlides[selectedSlide].widgets as Widget[]),
				];

				let widgetIndex = (
					newPageModel.presentationSlides[selectedSlide].widgets as Widget[]
				).findIndex((w: Widget) => w.id === widget.id);
				if (widgetIndex < 0) {
					widgetIndex = newWidgets.length;
				}
				newWidgets[widgetIndex] = {
					...widget,
				};
				newPageModel.presentationSlides[selectedSlide].widgets = newWidgets;
			}
			updateModel(newPageModel.presentationSlides[selectedSlide].widgets as Widget[]);
			setCurrentSlide({ ...newPageModel.presentationSlides[selectedSlide] });
		},
		[pageModel, selectedSlide]
	);

	const handleChartEditClick = useCallback(
		(widget: Widget) => {
			if (widget.chart) {
				presentationModel.id &&
					navigate(`/present/${presentationModel.id ?? 0}/chart/${widget.chart}`);
			}
		},
		[presentationModel]
	);

	const removeWidget = useCallback((widget: Widget) => {
		setPageModel((currentPageModel) => {
			currentPageModel.presentationSlides[selectedSlide].widgets = [
				...(currentPageModel.presentationSlides[selectedSlide].widgets as Widget[]).filter(
					(w: Widget) => {
						return w.id != widget.id;
					}
				),
			];
			return currentPageModel;
		});
		setCurrentSlide(pageModel.presentationSlides[selectedSlide]);
	}, []);

	const handleNameChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
		setPageModel({ ...pageModel, name: value });
	};

	const handlePresentaionSave: (isPresentationSaveAndNavigate?: boolean) => void = (
		isPresentationSaveAndNavigate?: boolean
	) => {
		setUnSavedChanges(false);
		setLoading(true);

		let newPresentationId = 0;
		let presentationSlidesResponse: TPresentationSlide[] = [];
		const presentApiModel = getPresentModel();
		if (!presentApiModel.id) {
			Present.New(presentApiModel)
				.then((presentationResponse) => {
					newPresentationId = presentationResponse.id ?? 0;
					presentationSlidesResponse = presentationResponse.presentationSlides;
					setPageModel((currentModel) => {
						return { ...currentModel, id: newPresentationId };
					});
					navigate(`/present/edit/${newPresentationId}`);
				})
				.catch((_) => {
					setLoading(false);
					addToast('Presentation create failed.', AlertVariant.danger);
				});
		} else {
			Present.Edit(presentApiModel)
				.then((_editResponse) => {
					setLoading(false);
					addToast('Presentation updated.', AlertVariant.success);
				})
				.catch((_) => {
					setLoading(false);
					addToast('Presentation update failed.', AlertVariant.danger);
				});
		}
	};

	const getPresentModel = (): Present => {
		const presentModel = Present.Default();
		presentModel.id = pageModel.id;
		presentModel.name = pageModel.name;
		presentModel.owner = pageModel.owner;
		presentModel.folder = pageModel.folder;
		presentModel.presentationSlides = pageModel.presentationSlides.map((s) => {
			return {
				page: s.page,
				presentation: s.presentation,
				id: s.id,
				widgets: s.widgets.map((w) => {
					const thisWidget = w as Widget;
					return {
						id: thisWidget.isNew ? undefined : thisWidget.id,
						presentationSlide: thisWidget.presentationSlide,
						col: thisWidget.col,
						row: thisWidget.row,
						sizex: thisWidget.sizex,
						sizey: thisWidget.sizey,
						page: thisWidget.page,
						name: thisWidget.name,
						widget_type: thisWidget.widget_type,
						content: thisWidget.content,
						chart: thisWidget.chart,
						report: thisWidget.report,
						source: thisWidget.source,
						isNew: thisWidget.isNew,
						widgetChanged: thisWidget.widgetChanged,
						isEdit: thisWidget.isEdit,
						conditionalRules: thisWidget.conditionalRules,
					};
				}),
			};
		});
		presentModel.presentationFilters = (dashboardFilters ?? []).map((df) => {
			const filter: DashboardFilter = { ...df };
			if (df.isNew) {
				filter.id = undefined;
				filter.isNew = false;
			}
			return filter;
		});
		return presentModel;
	};

	return (
		<>
			<Card
				className={'present-details-container'}
				style={{ marginBottom: '0.24rem' }}
			>
				<CardBody style={{ paddingBottom: '1.5rem' }}>
					<Grid
						className="row-container"
						span={12}
					>
						<div className="start-container">
							<FormGroup
								label={'Presentation Name'}
								type="text"
								isRequired
								fieldId="name"
								helperTextInvalid={'Presentation Name is required'}
								helperTextInvalidIcon={<ExclamationCircleIcon />}
							>
								<TextInput
									isRequired
									type="text"
									aria-label={'Enter a Presentation Name'}
									placeholder={'Enter a Presentation Name'}
									value={pageModel.name}
									onChange={handleNameChange}
								/>
							</FormGroup>
							<SelectFolderDropdown
								folderId={pageModel.folder as number}
								folders={folders}
								onFolderSelect={(e, item) => {
									setPageModel({
										...pageModel,
										folder: parseInt(item.id ?? '0'),
									});
								}}
							/>
						</div>
						<GridItem span={6}>
							<Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
								<Button onClick={() => handlePresentaionSave()}>
									Save Presentation
								</Button>
							</Flex>
						</GridItem>
					</Grid>
					<DashboardFilters
						presentationId={presentationId ? parseInt(presentationId) : 0}
						setFilters={setDashboardFilters}
						setSelectedDate={updateSelectedDate}
						setUnSavedChanges={setUnSavedChanges}
						setDashboardFiltersToDelete={setDashboardFiltersToDelete}
					/>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<Grid hasGutter>
						<GridItem span={2}>
							<PresentationPageList
								slides={pageModel.presentationSlides}
								selectedPage={selectedSlide}
								addNewPage={addNewPage}
								removePage={removePage}
								updateSelectedPage={updateSelectedPage}
							/>
						</GridItem>
						<GridItem span={10}>
							<PresentationSlide
								slide={pageModel.presentationSlides[selectedSlide]}
								handlePresentationWidgetSave={handlePresentationWidgetChange}
								removeWidget={removeWidget}
								handleEditClick={handleChartEditClick}
								gridLayout={gridLayout}
								filters={dashboardFilters}
								selectedDate={selectedDate}
							/>
						</GridItem>
					</Grid>
				</CardBody>
			</Card>
		</>
	);
};

export default PresentationDetail;
