import { AlertVariant, Dropdown, DropdownItem, KebabToggle, Tooltip } from '@patternfly/react-core';
import { useEffect, useState } from 'react';
import './AlertTile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowDownRight,
	faArrowRight,
	faArrowUpRight,
	faCircleDashed,
	faHistory,
	faLineChart,
	faPencilSquare,
	faSend,
} from '@fortawesome/pro-solid-svg-icons';
import { AlertTrendEnum } from '../../enums/alert-trend.enum';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import AlertTakeActionModal from '../../helpers/helper-components/AlertTakeActionModal';
import { DraggableMenuItemData } from '../../types/databuilder/databuilder';
import AlertDefinitionModal from '../../helpers/helper-components/AlertDefinitionModal';
import { faSlider, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { AlertDefinition, TAlertDefinition } from '../../api/alerts/AlertDefinition';
import { populateDroppedFactsByDataframe } from '../../hooks/DataBuilderHooks';
import { useApplication, useIsGranted } from '../user/ApplicationProvider';
import { useNavigate } from 'react-router-dom';
import { Dataframe } from '../../api/dataframes/Dataframes';
import { AlertConditionsEnum } from '../../enums/alet-conditions.enum';
import { TKeyMeasure } from '../../api/analytics/KeyMeasure';
import { KeyMeasureFact } from '../../api/analytics/KeyMeasureFact';
import { useMount } from 'react-use';
import { Permission } from '../../enums/permission.enum';
import { TAlertCalculation } from '../../api/alerts/AlertCalculation';
import DeleteConfirmationModal from '../../helpers/helper-components/DeleteConfirmationModal';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';

export type AlertTileProps = {
	id?: number;
	trend: AlertTrendEnum;
	alertValue: string;
	alertName: string;
	yoyPercentage?: number;
	existingAlert?: TAlertDefinition;
	refetchAlertDefinitions?: () => void;
	alertCalculations?: TAlertCalculation[];
	getAllAlertDefinitions: () => void;
};

function AlertTile(props: AlertTileProps) {
	const iconMap: Map<string, IconDefinition> = new Map<string, IconDefinition>([
		['down-trend', faArrowDownRight],
		['up-trend', faArrowUpRight],
		['no-change', faArrowRight],
		['just-info', faCircleDashed],
	]);

	const [isOpen, setIsOpen] = useState(false);
	const [isTakeActionClicked, setIsTakeActionClicked] = useState(false);
	const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
	const [droppedFacts, setDroppedFacts] = useState<DraggableMenuItemData[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [keymeasures, setKeymeasures] = useState<TKeyMeasure[]>([]);
	const navigate = useNavigate();
	const { measures } = useApplication();
	const [existingComparisonKeyMeasureFactName, setExistingComparisonKeyMeasureFactName] =
		useState<string>();
	const isGranted = useIsGranted();
	const hasEditAlertsPerm = isGranted(Permission.EditAlert);
	const hasDeleteAlertPerm = isGranted(Permission.DeleteAlert);
	const [alertCalculation, setAlertCalculation] = useState<TAlertCalculation>();
	const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState<boolean>(false);
	const { addToast } = useToast();

	useMount(() => {
		const comparisonKeyMeasureFact = props.existingAlert?.comparisonKeyMeasureFact;

		if (comparisonKeyMeasureFact) {
			void KeyMeasureFact.Get(+comparisonKeyMeasureFact).then((response) => {
				setExistingComparisonKeyMeasureFactName(response.name);
			});
		}
	});

	useEffect(() => {
		const arrayAlertCalculations = Array.isArray(props.alertCalculations)
			? props.alertCalculations
			: Object.values(props.alertCalculations ?? []);

		if (arrayAlertCalculations && arrayAlertCalculations.length > 0) {
			const alertCalculation = arrayAlertCalculations.find(
				(x) => x.alert_definition_id == props.id
			);

			if (alertCalculation) {
				setAlertCalculation(alertCalculation);
			}
		}
	}, [props.alertCalculations]);

	const onToggle = () => {
		setIsOpen(!isOpen);
	};

	const setIsActionTaken = () => {
		setIsTakeActionClicked(true);
	};

	const closeModal = () => {
		setIsTakeActionClicked(false);
	};

	const closeAlertModal = () => {
		setIsOpenAlertModal(false);
	};

	const openAlertModal = (isFirstRender = false) => {
		setIsOpenAlertModal(true);
		setIsLoading(true);

		AlertDefinition.Get([], props.id!)
			.then((response) => {
				const dataframeId = +response.dataframe;
				void Dataframe.Get(dataframeId, ['datasets']).then((dataframeResponse) => {
					const factsToSet = populateDroppedFactsByDataframe(dataframeResponse, measures);
					setDroppedFacts(factsToSet);
					setKeyMeasuresBasedOnFacts(factsToSet);

					if (!isFirstRender) setIsLoading(false);
				});
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	//workaround for key measure fact dropdown not populating selected item on initial render
	const open = () => {
		openAlertModal(true);
		setTimeout(() => {
			setIsOpenAlertModal(false);
			openAlertModal();
		}, 1000);
	};

	const navigateToChart = () => {
		navigate(`/analyze/charts/view/chart/${props.existingAlert?.chart ?? 0}`);
	};

	const setKeyMeasuresBasedOnFacts = (droppedFacts: DraggableMenuItemData[]) => {
		const droppedFactsKeyValue = droppedFacts.map((x) => ({
			id: x.data?.id,
			value: x.data?.title,
		}));
		const result = measures
			.map((keymeasure) => {
				// Filter the KeyMeasureFacts to only include those that match droppedFactsKeyValue
				const filteredFacts = keymeasure.keyMeasureFacts.filter((fact) =>
					droppedFactsKeyValue.some((droppedFact) => droppedFact.id === fact.id)
				);

				// Return the keymeasure only if there are matching facts
				return filteredFacts.length > 0
					? { ...keymeasure, keyMeasureFacts: filteredFacts }
					: null;
			})
			.filter((keymeasure) => keymeasure !== null) as TKeyMeasure[]; // Remove any keymeasures without matching facts

		setKeymeasures(result);
	};

	const closeDeleteModal = () => {
		setIsDeleteFolderModalOpen(false);
	};

	const openDeleteModal = () => {
		setIsDeleteFolderModalOpen(true);
	};

	const DeleteAlert = () => {
		const alertId = props.id!;
		if (alertId != 0) {
			AlertDefinition.Delete(alertId)
				.then((response) => {
					closeDeleteModal();
					props.getAllAlertDefinitions();
				})
				.catch(() => {
					addToast('An error occured while Deleting the Alert', AlertVariant.danger);
				});
		}
	};

	const dropdownItems = [
		hasEditAlertsPerm ? (
			<DropdownItem
				key="item-t-action-1"
				className="alert-action"
				onClick={setIsActionTaken}
			>
				Take Action <FontAwesomeIcon icon={faSend} />
			</DropdownItem>
		) : (
			<></>
		),
		hasEditAlertsPerm ? (
			<DropdownItem
				key="item-edit-1"
				className="alert-action"
				onClick={open}
			>
				Edit Alert <FontAwesomeIcon icon={faPencilSquare} />
			</DropdownItem>
		) : (
			<></>
		),
		hasDeleteAlertPerm ? (
			<DropdownItem
				key="item-delete-1"
				className="alert-action"
				onClick={openDeleteModal}
			>
				Delete Alert <FontAwesomeIcon icon={faTrashAlt} />
			</DropdownItem>
		) : (
			<></>
		),
		<DropdownItem
			key="item-v-history-1"
			className="alert-action"
		>
			View History <FontAwesomeIcon icon={faHistory} />
		</DropdownItem>,
		<DropdownItem
			key="item-v-chart-1"
			className="alert-action"
			onClick={navigateToChart}
		>
			View Chart <FontAwesomeIcon icon={faLineChart} />
		</DropdownItem>,
	];

	const showYoy = props.yoyPercentage !== undefined && props.yoyPercentage !== null;

	let trendKey: 'Even' | 'Down' | 'Up' = 'Even';
	if (props.yoyPercentage !== void 0) {
		if (props.yoyPercentage > 0) {
			trendKey = 'Up';
		}

		if (props.yoyPercentage < 0) {
			trendKey = 'Down';
		}
	}

	const alertValue = `${
		//eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		props.existingAlert?.condition == AlertConditionsEnum.PercentChangeFromPreviousPeriod ||
		props.existingAlert?.condition ==
			AlertConditionsEnum.PercentChangeFromPreviousPeriodPlusMinus
			? 'Percent Changed'
			: props.existingAlert?.condition
	}${' '}
	${
		existingComparisonKeyMeasureFactName
			? `${existingComparisonKeyMeasureFactName} (Fact)`
			: props.alertValue
	}`;

	const needsEllipse = alertValue.replaceAll(' ', '').length > 40;
	const ellipseAlertValue = needsEllipse ? `${alertValue.substring(0, 40)}...` : alertValue;
	const alertValueHtml = needsEllipse ? (
		<Tooltip content={alertValue}>
			<span className="pointer alert-value">{ellipseAlertValue}</span>
		</Tooltip>
	) : (
		<span className="alert-value">{alertValue}</span>
	);

	return (
		<div
			className={'tile bordered'}
			style={{ color: props.existingAlert?.triggered ? props.existingAlert?.color : '' }}
			data-testid="alert-tile"
		>
			<div className="tile-content">
				<div className="tile-column">
					<FontAwesomeIcon
						data-testid="trend-icon"
						icon={iconMap.get(props.trend) ?? faCircleDashed}
						style={{
							color: props.existingAlert?.triggered ? props.existingAlert?.color : '',
						}}
					/>
				</div>
				<div className="tile-column">
					<div className="column-content">
						<span
							className="tile-header"
							data-testid="alertName"
						>
							{props.alertName}
						</span>
						<br />
						<span data-testid="alert-value">
							{alertValueHtml}
							<br />
							{props.existingAlert?.triggered ? (
								<span
									className="push-down bolder alert val"
									data-testid="alert-met-by"
								>
									Met by {alertCalculation?.datapoints_met} datapoint(s)
									<br /> on{' '}
									{alertCalculation?.date_met
										? new Date(alertCalculation?.date_met).toLocaleDateString(
												'en-US'
										  )
										: ''}
								</span>
							) : (
								<span
									data-testid="alert-not-yet-met"
									className="push-down bolder alert-val"
								>
									Alert not yet evaluated
								</span>
							)}
						</span>
					</div>
				</div>

				{showYoy ? (
					<div
						className="tile-column tile-content-right"
						data-testid="yoy-container"
					>
						<div className="column-content">
							<span
								className="tile-header"
								data-testid="yoy-value"
							>
								{props.yoyPercentage}%
							</span>
							<span className="sub-text">
								{trendKey} <br /> YOY
							</span>
						</div>
					</div>
				) : null}
			</div>
			<div className="tile-footer">
				<div className="spacer" />
				<div className="footer-content">
					<Dropdown
						toggle={<KebabToggle onToggle={onToggle} />}
						isOpen={isOpen}
						isPlain
						dropdownItems={dropdownItems}
						className="alert-dropdown"
						direction="up"
					>
						<FontAwesomeIcon icon={faSlider} />
					</Dropdown>
				</div>
			</div>

			<AlertTakeActionModal
				defaultAction="Take Action"
				isOpen={isTakeActionClicked}
				onClose={closeModal}
			/>

			<AlertDefinitionModal
				isOpen={isOpenAlertModal}
				onClose={closeAlertModal}
				droppedFacts={droppedFacts ?? []}
				existingAlert={props.existingAlert}
				isLoadingForEdit={isLoading}
				filteredKeyMeasures={keymeasures}
				refetchAlertDefinitions={props.refetchAlertDefinitions}
			/>

			<DeleteConfirmationModal
				isOpen={isDeleteFolderModalOpen}
				onClose={closeDeleteModal}
				onSubmit={DeleteAlert}
			/>
		</div>
	);
}

export default AlertTile;
