import { Modal, Text } from '@patternfly/react-core';
import CenteredPage from '../../layout/CenteredPage';
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires
const packageJson = require('../../../package.json');
const today = new Date();
const month = (today.getMonth() + 1).toString().padStart(2, '0');
const day = today.getDate().toString().padStart(2, '0');
const year = today.getFullYear();

// Combine them into MM-DD-YYYY format
const defaultDate = `${month}-${day}-${year}`;

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

const formatDate = (dateString: string): string => {
	const date = new Date(dateString);
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const day = String(date.getDate()).padStart(2, '0');
	const year = date.getFullYear();

	return `${month}-${day}-${year}`;
};

function AboutModal(props: Props) {
	const message = (
		<>
			<div className="text-center">
				{/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
				Version / Release #: {packageJson.version} <br />
				Release Date:{' '}
				{/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-unsafe-argument */}
				{packageJson.version_date ? formatDate(packageJson.version_date) : defaultDate}
				<span className="spacer-sm"></span>
				<Text>(c) {new Date().getFullYear()} ZeroedIn Technologies, LLC.</Text>
			</div>
		</>
	);

	return (
		<>
			<Modal
				title=""
				variant="medium"
				width={350}
				isOpen={props.isOpen}
				onClose={props.onClose}
			>
				<CenteredPage
					description={'ZeroedIn Analytics Platform'}
					notice={message}
					children={undefined}
				></CenteredPage>
			</Modal>
		</>
	);
}

export default AboutModal;
