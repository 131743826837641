import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { useMount } from 'react-use';
import { TUserDimensionFilter, UserDimensionFilter } from '../../api/security/UserDimensionFilter';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { AlertVariant, Button, Modal, ModalBoxFooter, ModalVariant } from '@patternfly/react-core';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';
import SchnurForm, {
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { User } from '../../api/security/User';
import { Dimension, TDimension } from '../../api/analytics/Dimension';

export default function UserDimensionFilters(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [tableData, setTableData] = useState<TUserDimensionFilter[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [activeUserDimFilter, setActiveUserDimFilter] = useState<TUserDimensionFilter>(
		UserDimensionFilter.Default() as TUserDimensionFilter
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
	const [dimensions, setDimensions] = useState<TDimension[]>([]);
	const { userId } = useParams();
	const [currentUsername, setCurrentUsername] = useState('');

	const selectedColumns: Column<TUserDimensionFilter>[] = [
		{
			title: 'User',
			columnName: 'user',
			customAccessor: () => {
				return currentUsername;
			},
		},
		{
			title: 'Dimension',
			columnName: 'dimension',
			customAccessor: (item) => {
				const returnItem = dimensions.find((x) => x.id == +item.dimension);
				return returnItem?.display_name ?? returnItem?.name ?? '';
			},
		},
		{
			title: 'Dimension Value',
			columnName: 'value',
		},
		{
			title: 'Logic Group',
			columnName: 'logic_group',
		},
	];

	const actions: Action<TUserDimensionFilter>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			permission: Permission.EditUser,
			callback: (item) => {
				setActiveUserDimFilter({ ...item, username: currentUsername });
				setIsModalOpen(true);
			},
		},
		{
			name: 'Delete',
			permission: Permission.EditUser,
			callback: (item) => {
				setActiveUserDimFilter({ ...item, username: currentUsername });
				setIsDeleteModalOpen(true);
			},
		},
	];

	const formProperties: Field<TUserDimensionFilter>[] = [
		{
			title: 'User',
			columnName: 'username',
			uiSchema: {
				type: UIType.TEXT,
			},
			disabled: true,
		},
		{
			title: 'Dimension',
			columnName: 'dimension',
			uiSchema: {
				type: UIType.SELECT,
				options: dimensions.map(
					(dim) => ({ key: dim.id, value: dim.display_name ?? dim.name } as ISelectOption)
				),
				initialSelection:
					'id' in activeUserDimFilter ? activeUserDimFilter.dimension : undefined,
				onSelect: (value: ISelectOption) => value.key,
			},
			required: true,
		},
		{
			title: 'Dimension Value',
			columnName: 'value',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Logic Group',
			columnName: 'logic_group',
			uiSchema: {
				type: UIType.NUMBER,
			},
		},
	];

	const getUserDimensionFilters = (userIdParsed: number) => {
		UserDimensionFilter.GetAll(userIdParsed)
			.then((response) => {
				setTableData(response);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to fetch User Dimension Filters. Please try again later.',
					AlertVariant.danger
				);
			});
	};

	useMount(() => {
		const userIdParsed = userId ? +userId : undefined;

		if (userIdParsed) {
			setTableLoading(true);

			void User.Get(userIdParsed).then((response) => {
				setCurrentUsername(response.name);
				setActiveUserDimFilter({
					...activeUserDimFilter,
					username: response.name,
					user: userIdParsed,
				});
			});

			getUserDimensionFilters(userIdParsed);
		}

		void Dimension.GetAll().then((response) => {
			setDimensions(response);
		});
	});

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Users Dimensions Filters"
					pageDescription="Manage Users Dimensions Filters."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const addButton = (
		<PermissionButton
			permission={Permission.CreateUser}
			data-testid={'user-dim-filter-create'}
			variant={'primary'}
			onClick={() => {
				setActiveUserDimFilter({
					...(UserDimensionFilter.Default() as TUserDimensionFilter),
					username: currentUsername,
					user: userId ? +userId : 0,
				});
				setIsModalOpen(true);
			}}
		>
			New User Dimension Filter
		</PermissionButton>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleDeleteModalClose = () => {
		setIsDeleteModalOpen(false);
	};

	const handleDelete = () => {
		setTableLoading(true);
		UserDimensionFilter.Delete(activeUserDimFilter.id)
			.then(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				setTableData((prev) => prev.filter((user) => user.id !== activeUserDimFilter.id));
				addToast('User Dimension Filter deleted successfully.', AlertVariant.success);
			})
			.catch(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				addToast(
					'An error occurred while trying to delete User Dimension Filter. Please try again later.',
					AlertVariant.danger
				);
			});
	};

	const handleSuccess = (_value: TUserDimensionFilter) => {
		const userIdParsed = userId ? +userId : 0;

		getUserDimensionFilters(userIdParsed);
		setIsModalOpen(false);
	};

	const userDimFilterTable = (
		<ZiTable<TUserDimensionFilter>
			ariaLabel={'User Dimension Filters'}
			columns={selectedColumns}
			data={tableData}
			caption={`User Dimension Filters for ${currentUsername}`}
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="User Dimension Filters"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TUserDimensionFilter>
					title={'User Dimension Filters'}
					fields={formProperties}
					initialSubject={activeUserDimFilter}
					isLoading={isFormLoading}
					onSubmit={(request) => {
						setIsFormLoading(true);
						request.logic_group = +request.logic_group;

						if (request.id) {
							UserDimensionFilter.Update(request)
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the User. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							UserDimensionFilter.New(request)
								.then((newUser) => {
									handleSuccess(newUser);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the User. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>

			<Modal
				title="User Dimension Delete Confirmation"
				variant="small"
				isOpen={isDeleteModalOpen}
				onClose={handleDeleteModalClose}
				className="delete-modal"
			>
				<hr />
				<br />
				<div className="text-center">
					<h3>
						Are you sure you want to delete {activeUserDimFilter.username}'s specified
						filter?
					</h3>
				</div>
				<br />
				<hr />
				<ModalBoxFooter className="pull-right add-question-footer">
					<Button
						variant="secondary"
						onClick={handleDeleteModalClose}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleDelete}
					>
						Delete
					</Button>
				</ModalBoxFooter>
			</Modal>

			<React.Fragment>
				<FilterTableLayout
					table={userDimFilterTable}
					layoutActions={[addButton]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
