import React, { ReactElement } from 'react';
import {
	Button,
	Form,
	FormGroup,
	Modal,
	ModalVariant,
	NumberInput,
	Select,
	SelectDirection,
	SelectOption,
	SelectOptionObject,
	SelectVariant,
	TextInput,
} from '@patternfly/react-core';
import { TPeriod } from '../../../api/analytics/Period';
import { DraggableMenuItemData } from '../../../types/databuilder/databuilder';

export type IFactOptionsModalProps = {
	periods: TPeriod[];
	fact: DraggableMenuItemData | null;
	numDecimals?: number | null;
	handleClose: () => void;
	handleSave: (fact: DraggableMenuItemData) => void;
};

const FactOptionsModal = (props: IFactOptionsModalProps): ReactElement => {
	const [isDecimalDropdownOpen, setIsDecimalDropdownOpen] = React.useState(false);
	const [isDefaultDropdownOpen, setIsDefaultDropdownOpen] = React.useState(false);
	const [isPeriodDropdownOpen, setIsPeriodDropdownOpen] = React.useState(false);
	const [title, setTitle] = React.useState(props.fact ? props.fact?.data?.title : '');
	const [minimumValue, setMinimumValue] = React.useState(
		props.fact ? props.fact?.data?.minValue : undefined
	);
	const [maximumValue, setMaximumValue] = React.useState(
		props.fact ? props.fact?.data?.maxValue : undefined
	);
	const [hidden, setHidden] = React.useState(false);
	const [selectedDecimal, setSelectedDecimal] = React.useState<string | SelectOptionObject>(
		props.fact && props.fact.data?.numDecimals !== undefined
			? props.fact.data?.numDecimals.toString()
			: props.numDecimals
			? props.numDecimals.toString()
			: ''
	);
	const [selectedDefault, setSelectedDefault] = React.useState<string | SelectOptionObject>(
		props.fact && props.fact.data?.defaultWhenNull ? props.fact.data?.defaultWhenNull : '***'
	);
	const [selectedPeriod, setSelectedPeriod] = React.useState<string | SelectOptionObject>(
		props.fact && props.fact.data?.period ? props.fact.data?.period.toString() : ''
	);

	const onCloseModal = () => {
		props.handleClose();
	};

	const onSaveModal = () => {
		const fact = Object.assign({}, props.fact);
		if (fact.data) {
			fact.data.defaultWhenNull = selectedDefault.toString();
			fact.data.numDecimals = parseInt(selectedDecimal.toString());
			fact.data.hidden = hidden;
			fact.data.title = title ?? '';
			fact.data.minValue = minimumValue;
			fact.data.maxValue = maximumValue;

			if (selectedPeriod) {
				fact.data.period = parseInt(selectedPeriod.toString());
			}
		}

		props.handleSave(fact);
	};

	const onToggleDecimalDropdown = (isOpen: boolean) => {
		setIsDecimalDropdownOpen(isOpen);
	};

	const onToggleDefaultDropdown = (isOpen: boolean) => {
		setIsDefaultDropdownOpen(isOpen);
	};

	const onTogglePeriodDropdown = (isOpen: boolean) => {
		setIsPeriodDropdownOpen(isOpen);
	};

	const onSelectDecimalDropdown = (
		event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
		selection: string | SelectOptionObject,
		isPlaceholder?: boolean | undefined
	) => {
		if (!isPlaceholder) {
			setSelectedDecimal(selection);
			setIsDecimalDropdownOpen(false);
		}
	};

	const onSelectDefaultDropdown = (
		event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
		selection: string | SelectOptionObject,
		isPlaceholder?: boolean | undefined
	) => {
		if (!isPlaceholder) {
			setSelectedDefault(selection);
			setIsDefaultDropdownOpen(false);
		}
	};

	const onSelectPeriodDropdown = (
		event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
		selection: string | SelectOptionObject,
		isPlaceholder?: boolean | undefined
	) => {
		if (!isPlaceholder) {
			setSelectedPeriod(selection);
			setIsPeriodDropdownOpen(false);
		}
	};

	const onTitleChange = (title: string) => {
		setTitle(title);
	};

	const getDecimalOptions = (): JSX.Element[] => {
		return [
			<SelectOption
				value={'0'}
				key={'decimal_0'}
			>
				0 {props.numDecimals === 0 ? '(Default)' : ''}
			</SelectOption>,
			<SelectOption
				value={'1'}
				key={'decimal_1'}
			>
				0.0 {props.numDecimals === 1 ? '(Default)' : ''}
			</SelectOption>,
			<SelectOption
				value={'2'}
				key={'decimal_2'}
			>
				0.00 {props.numDecimals === 2 || props.numDecimals === undefined ? '(Default)' : ''}
			</SelectOption>,
			<SelectOption
				value={'3'}
				key={'decimal_3'}
			>
				0.000 {props.numDecimals === 3 ? '(Default)' : ''}
			</SelectOption>,
			<SelectOption
				value={'4'}
				key={'decimal_4'}
			>
				0.0000 {props.numDecimals === 4 ? '(Default)' : ''}
			</SelectOption>,
			<SelectOption
				value={'5'}
				key={'decimal_5'}
			>
				0.00000 {props.numDecimals === 5 ? '(Default)' : ''}
			</SelectOption>,
			<SelectOption
				value={'6'}
				key={'decimal_6'}
			>
				0.000000 {props.numDecimals === 6 ? '(Default)' : ''}
			</SelectOption>,
		];
	};

	const getDefaultOptions = (): JSX.Element[] => {
		return [
			<SelectOption
				value={'***'}
				key={'default_*'}
			>
				*** (Default)
			</SelectOption>,
			<SelectOption
				value={'0'}
				key={'default_0'}
			>
				0
			</SelectOption>,
			<SelectOption
				value={'N/A'}
				key={'default_na'}
			>
				N/A
			</SelectOption>,
		];
	};

	const getPeriodOptions = (): JSX.Element[] => {
		const periods = [
			<SelectOption
				key={'select_a_period_placeholder'}
				value="Select a period"
				isPlaceholder
			/>,
		];

		return [
			...periods,
			...props.periods.map((type): ReactElement => {
				return (
					<SelectOption
						value={type.id.toString()}
						key={type.code}
					>
						{type.name}
					</SelectOption>
				);
			}),
		];
	};

	const onMinValMinus = () => {
		const newValue = (minimumValue || 0) - 1;
		setMinimumValue(newValue);
	};

	const onMinValChange = (event: React.FormEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		setMinimumValue(value === '' ? undefined : +value);
	};

	const onMinValPlus = () => {
		const newValue = (minimumValue || 0) + 1;
		setMinimumValue(newValue);
	};

	const onMaxValMinus = () => {
		const newValue = (maximumValue || 0) - 1;
		setMaximumValue(newValue);
	};

	const onMaxValChange = (event: React.FormEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		setMaximumValue(value === '' ? undefined : +value);
	};

	const onMaxValPlus = () => {
		const newValue = (maximumValue || 0) + 1;
		setMaximumValue(newValue);
	};

	return (
		<React.Fragment>
			<Modal
				title="Options"
				variant={ModalVariant.small}
				isOpen={true}
				onClose={onCloseModal}
				actions={[
					<Button
						key="confirm"
						variant="primary"
						onClick={onSaveModal}
					>
						Ok
					</Button>,
					<Button
						key="cancel"
						variant="secondary"
						onClick={onCloseModal}
					>
						Cancel
					</Button>,
				]}
			>
				<Form>
					<FormGroup
						label="Decimal Precision"
						fieldId="decimal_precision"
					>
						<Select
							variant={SelectVariant.single}
							onToggle={onToggleDecimalDropdown}
							onSelect={onSelectDecimalDropdown}
							selections={selectedDecimal}
							isOpen={isDecimalDropdownOpen}
							isDisabled={false}
							direction={SelectDirection.down}
							menuAppendTo={() => document.body}
						>
							{getDecimalOptions()}
						</Select>
					</FormGroup>
					<FormGroup
						label="Default value when NULL"
						fieldId="default_when_null"
					>
						<Select
							variant={SelectVariant.single}
							onToggle={onToggleDefaultDropdown}
							onSelect={onSelectDefaultDropdown}
							selections={selectedDefault}
							isOpen={isDefaultDropdownOpen}
							isDisabled={false}
							direction={SelectDirection.down}
							menuAppendTo={() => document.body}
						>
							{getDefaultOptions()}
						</Select>
					</FormGroup>
					<FormGroup
						label="Title"
						fieldId="title"
					>
						<TextInput
							type="text"
							id="title"
							name="title"
							value={title}
							onChange={onTitleChange}
						/>
					</FormGroup>
					<FormGroup
						label="Minimum Value"
						fieldId="minimum_value"
					>
						<NumberInput
							value={minimumValue}
							onMinus={onMinValMinus}
							onChange={onMinValChange}
							onPlus={onMinValPlus}
							inputName="input"
							inputAriaLabel="number input"
							minusBtnAriaLabel="minus"
							plusBtnAriaLabel="plus"
							allowEmptyInput
						/>
					</FormGroup>
					<FormGroup
						label="Maxiimum Value"
						fieldId="maximum_value"
					>
						<NumberInput
							value={maximumValue}
							onMinus={onMaxValMinus}
							onChange={onMaxValChange}
							onPlus={onMaxValPlus}
							inputName="input"
							inputAriaLabel="number input"
							minusBtnAriaLabel="minus"
							plusBtnAriaLabel="plus"
							allowEmptyInput
						/>
					</FormGroup>
					{/* <FormGroup isInline>
						<Checkbox
							label="Hidden"
							id="hidden"
							isChecked={hidden}
							onChange={setHidden}
						/>
					</FormGroup>
					<FormGroup
						label="Display Date Period"
						fieldId="period"
					>
						<Select
							variant={SelectVariant.single}
							onToggle={onTogglePeriodDropdown}
							onSelect={onSelectPeriodDropdown}
							selections={selectedPeriod}
							isOpen={isPeriodDropdownOpen}
							isDisabled={false}
							direction={SelectDirection.down}
							menuAppendTo={() => document.body}
						>
							{getPeriodOptions()}
						</Select>
					</FormGroup> */}
				</Form>
			</Modal>
		</React.Fragment>
	);
};

export default FactOptionsModal;
