import React, { useState } from 'react';
import { AlertVariant, Button, Popover, TextContent, Text, Tooltip } from '@patternfly/react-core';
import { TUserFilter } from '../../api/types';
import { useMount } from 'react-use';
import { User } from '../../api/security/User';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import LockedIcon from '@patternfly/react-icons/dist/esm/icons/locked-icon';
import {
	Caption,
	InnerScrollContainer,
	OuterScrollContainer,
	TableComposable,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@patternfly/react-table';
import { useApplication } from '../user/ApplicationProvider';
import './UserSecureFiltersIcon.scss';

const UserSecureFiltersIcon = () => {
	const { dimensions } = useApplication();
	const [userFilters, setUserFilters] = useState<TUserFilter[]>([]);
	const { addToast } = useToast();

	useMount(() => {
		User.GetUserFilters()
			.then((response) => {
				if (
					response &&
					(response.userDimension.length || response.userDimensionAttribute.length)
				) {
					setUserFilters([...response.userDimension, ...response.userDimensionAttribute]);
				}
			})
			.catch((error) => {
				console.log(error);
				addToast('Error ocurred fetching user secure filters', AlertVariant.danger);
			});
	});

	const getName = (filter: TUserFilter) => {
		if (filter.dimension_id) {
			const dim = dimensions.find((dim) => dim.id === filter.dimension_id);
			return dim ? dim.display_name ?? dim.name : '';
		} else if (filter.attribute_id) {
			const dimAttrs = dimensions.flatMap((dim) => dim.dimensionAttributes);
			const dimAttr = dimAttrs.find((attr) => attr.id === filter.attribute_id);
			return dimAttr ? dimAttr.name : '';
		}

		return '';
	};

	const getBody = () => {
		return (
			<div className="user-secure-filters-table-container">
				<OuterScrollContainer>
					<InnerScrollContainer>
						<TableComposable
							variant={'compact'}
							borders={false}
							isStickyHeader
						>
							<Caption className="caption-text">
								Your view is being restricted to data matching the criteria below.
							</Caption>
							<Thead>
								<Tr>
									<Th>Name</Th>
									<Th>Value</Th>
								</Tr>
							</Thead>
							<Tbody>
								{userFilters.length &&
									userFilters
										.sort((a, b) => a.logic_group - b.logic_group)
										.map((dim, index) => (
											<>
												<Tr key={dim.id}>
													<Td>{getName(dim)}</Td>
													<Td>{dim.value}</Td>
												</Tr>
												<>
													{index < userFilters.length - 1 &&
														userFilters[index + 1].logic_group !=
															dim.logic_group && (
															<Tr className="logic-group-text">
																<Td colSpan={2}>
																	<TextContent>
																		<Text>
																			<b>--OR--</b>
																		</Text>
																	</TextContent>
																</Td>
															</Tr>
														)}
												</>
											</>
										))}
							</Tbody>
						</TableComposable>
					</InnerScrollContainer>
				</OuterScrollContainer>
			</div>
		);
	};

	const template = (
		<Popover
			aria-label="Basic popover"
			className={'user-secure-filters-icon-container'}
			headerContent={'User Secure Filters'}
			bodyContent={getBody()}
			minWidth={'600px'}
			position={'right'}
			enableFlip={true}
		>
			<Button
				variant="plain"
				aria-label="Action"
			>
				<Tooltip content={'Click to view your secure filters'}>
					<LockedIcon />
				</Tooltip>
			</Button>
		</Popover>
	);
	if (userFilters.length) {
		return template;
	}

	return null;
};

export default UserSecureFiltersIcon;
