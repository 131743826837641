import _ from 'lodash';
import { GenericStringKeyValueResponse } from '../api/types';
import { TFolder } from '../api/types/folder';

export const buildFolderDisplayForDropdown = (folderKeys: string[], folderItems: TFolder[]) => {
	let kvpFolders: GenericStringKeyValueResponse<string>[] = [];

	folderKeys.forEach((folderKey) => {
		const folder = folderItems.find((x) => x?.id == +folderKey);
		let folderName = folder?.name;

		//supports up to 3 levels of nested folders
		if (folder?.parent && folderItems && folderItems.length > 0) {
			const parentFolderId = folder.parent;

			if (parentFolderId) {
				const parentFolder2 = folderItems.find((x) => x?.id == parentFolderId);

				if (parentFolder2) {
					folderName = `${parentFolder2.name} / ${folderName ?? ''}`;
					const parentFolder3 = folderItems.find((x) => x?.id == parentFolder2.parent);

					if (parentFolder3) {
						folderName = `${parentFolder3.name} / ${folderName}`;
					}
				}
			}
		}

		kvpFolders.push({ key: folderKey, value: folderName ?? '' });
	});

	kvpFolders = _.orderBy(kvpFolders, ['value'], ['asc']);
	kvpFolders = [...kvpFolders.slice(1), kvpFolders[0]];

	return kvpFolders;
};
