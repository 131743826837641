import { Permission } from '../enums/permission.enum';

export type HeaderMenuItem = {
	key: string;
	displayText: string;
	url?: string;
	subMenuItems?: HeaderMenuItem[];
	permission?: Permission;
};

export const headerMenuItems: HeaderMenuItem[] = [
	{
		key: 'Header_Menu_Home',
		displayText: 'Home',
		url: '/',
	},
	{
		key: 'Header_Menu_Discover',
		displayText: 'Discover',
		url: 'discover',
		permission: Permission.CanViewDiscoverPage,
	},
	{
		key: 'Header_Menu_Analyze',
		displayText: 'Analyze',
		url: 'analyze',
		permission: Permission.CanViewAnalyzePage,
	},
	{
		key: 'Header_Menu_Report',
		displayText: 'Report',
		url: 'report',
		permission: Permission.CanViewReportPage,
	},
	{
		key: 'Header_Menu_Present',
		displayText: 'Present',
		url: 'present',
		permission: Permission.CanViewPresentPage,
	},
	// {
	// 	key: 'Header_Menu_Apps',
	// 	displayText: 'Apps',
	// 	subMenuItems: [
	// 		{
	// 			key: 'Header_Menu_Surveyor',
	// 			displayText: 'People Surveys',
	// 			url: 'surveyor',
	// 		},
	// 		{
	// 			key: 'Header_Menu_Orgchart',
	// 			displayText: 'Org Charting',
	// 			url: 'orgcharter',
	// 		},
	// 		{
	// 			key: 'Header_Menu_Workforceplan',
	// 			displayText: 'Workforce Planner',
	// 			url: 'workforce_planner',
	// 		},
	// 	],
	// 	permission: Permission.CanViewAppsPage,
	// },
];
