import React, { ReactElement, useState } from 'react';
import {
	Brand,
	Masthead,
	MastheadMain,
	MastheadContent,
	MastheadBrand,
	Tooltip,
	Text,
} from '@patternfly/react-core';
import './Header.scss';
import HeaderContent from './HeaderContent';
import { HeaderMenuItem } from '../../helpers/headerHelper';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { ZoneSettingEnum } from '../../api/zone/ZoneSetting';
import { useApplication } from '../../components/user/ApplicationProvider';

type Props = {
	headerText?: string;
	logoImg: string;
	menuItems: HeaderMenuItem[];
};

export default function Header(props: Props): ReactElement {
	const { logoImg, menuItems } = props;
	const navigate = useNavigate();
	const [isFrameEnabled, setIsIframeEnabled] = useState<boolean>(true);
	const { zoneSettings } = useApplication();

	useMount(() => {
		const iframeSetting = zoneSettings.find(
			(setting) => setting.zone_setting_enum_id === ZoneSettingEnum.iframeIntegration
		);

		if (iframeSetting) {
			if (!iframeSetting.enabled || iframeSetting.value != '1') {
				setIsIframeEnabled(false);
			}
		}
	});
	return (
		<Masthead style={{ paddingLeft: 0 }}>
			<MastheadMain>
				{!isFrameEnabled && (
					<MastheadBrand className="pf-m-align-items-center pf-m-justify-content-space-between">
						<Tooltip
							content="Home"
							flipBehavior={['bottom']}
						>
							<Text
								style={{ display: 'inline-flex', fontSize: '24px', color: 'white' }}
								onClick={() => navigate('/')}
							>
								<Brand
									alt={'ZeroedIn Technologies'}
									src={logoImg}
									className="zin-header-logo"
								/>
							</Text>
						</Tooltip>
					</MastheadBrand>
				)}
				<MastheadContent>
					<HeaderContent menuItems={isFrameEnabled ? menuItems : menuItems.slice(1)} />
				</MastheadContent>
			</MastheadMain>
		</Masthead>
	);
}
