import React, { useCallback, useState } from 'react';
import { AlertVariant, Button, Modal } from '@patternfly/react-core';
import { Folder, TFolder } from '../../../api/foundational-elements/Folder';
import DefaultPresentOptionsView, {
	DefaultPresentOptionsViewProps,
} from './DefaultPresentOptionsView';
import { Present, TPresent } from '../../../api/present/Present';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { useMount } from 'react-use';
import { BlankPresentOptionsViewProps } from './BlankPresentOptionsView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';

export type ZiPresentOptionsModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

export type PresentCreationOptions = 'default' | 'blank' | 'grid' | 'flex' | 'copy';

const ZiPresentOptionsModal = (props: ZiPresentOptionsModalProps) => {
	const [currentView, setCurrentView] = useState<PresentCreationOptions>('default');
	const [folders, setFolders] = useState<TFolder[]>([]);
	const [selectedFolder, setSelectedFolder] = useState<TFolder | undefined>();
	const [presentation, setPresentation] = useState<TPresent>(Present.Default());
	const navigate = useNavigate();
	const { addToast } = useToast();
	const { parentFolderId } = useParams();

	useMount(() => {
		Folder.GetAll({ type: 'presentations' })
			.then((foldersResponse) => {
				if (foldersResponse) {
					setFolders([...foldersResponse]);
					if (parentFolderId) {
						setSelectedFolder(
							foldersResponse.find((f) => f.id === parseInt(parentFolderId))
						);
					}
				}
			})
			.catch(() => {
				addToast('Get all folders failed.', AlertVariant.danger);
			});
	});

	const handleNameChange = useCallback(
		(value: string, _event: React.FormEvent<HTMLInputElement>) => {
			setPresentation((presentation) => {
				return { ...presentation, name: value };
			});
		},
		[]
	);

	const handleSave = () => {
		navigate(`/present/create`);
		// Present.New({ ...presentation, owner: user.id, folder: selectedFolder?.id })
		// 	.then((response) => {
		// 		if (response.id) {
		// 			navigate(`/analyze/dashboards/${response.id}`);
		// 		}
		// 	})
		// 	.catch(() => {
		// 		addToast('Create dashboard failed.', AlertVariant.danger);
		// 	});
	};

	const defaultViewProps: DefaultPresentOptionsViewProps = {
		onViewChange: setCurrentView,
		handleCreate: handleSave,
	};

	const blankViewProps: BlankPresentOptionsViewProps = {
		onViewChange: setCurrentView,
		presentation: presentation,
		folders: folders,
		handleNameChange: handleNameChange,
		selectedFolder: selectedFolder,
		onSelectFolder: setSelectedFolder,
	};

	const contentMap: Map<PresentCreationOptions, JSX.Element> = new Map<
		PresentCreationOptions,
		JSX.Element
	>([
		['default', <DefaultPresentOptionsView {...defaultViewProps} />],
		// ['blank', <BlankPresentOptionsView {...blankViewProps} />],
		// ['grid', <BlankPresentOptionsView {...blankViewProps} />],
		// ['flex', <BlankPresentOptionsView {...blankViewProps} />],
		// ['copy', <CopyPresentOptionsView {...copyChartProps} />],
	]);

	const beforeClose = () => {
		setDefaultView();
		props.onClose();
	};

	const setDefaultView = (): void => {
		setCurrentView('default');
		setPresentation(Present.Default());
	};

	const backIcon = (
		<div className="back-btn">
			<Button
				variant="link"
				onClick={setDefaultView}
			>
				<FontAwesomeIcon icon={faArrowLeft} />
			</Button>
		</div>
	);

	return (
		<>
			<Modal
				data-testid="add-presentation-modal"
				isOpen={props.isOpen}
				onClose={beforeClose}
				variant="small"
				title="Add Presentation"
				actions={[
					// <Button
					// 	data-testid="create-button"
					// 	key="create"
					// 	variant="primary"
					// 	onClick={handleSave}
					// 	isDisabled={presentation.name === ''}
					// >
					// 	Create
					// </Button>,
					<Button
						data-testid="cancel-button"
						key="cancel"
						variant="link"
						onClick={beforeClose}
					>
						Cancel
					</Button>,
				]}
			>
				{currentView !== 'default' ? backIcon : null}
				{contentMap.get(currentView)}
			</Modal>
		</>
	);
};

export default ZiPresentOptionsModal;
