import React, { Dispatch, SetStateAction, useState } from 'react';
import {
	AlertVariant,
	Button,
	DropdownGroup,
	DropdownItem,
	FormGroup,
	Modal,
	TreeViewDataItem,
} from '@patternfly/react-core';
import { useMount } from 'react-use';
import { GenericStringKeyValueResponse, TDataframe } from '../../../api/types';
import { Dataframe } from '../../../api/dataframes/Dataframes';
import Loader from '../../util/Loader';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import SearchableTreeViewSelect, {
	dropdownToTreeViewData,
} from '../../form/Select/SearchableTreeViewSelect';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { TFolder } from '../../../api/types/folder';
import { buildFolderDisplayForDropdown } from '../../../helpers/dropdown-folder.helper';

export type DataframeCopyModel = {
	selectedFrame?: TDataframe;
	onSelectFrame: Dispatch<SetStateAction<TDataframe | undefined>>;
	isOpen: boolean;
	onClose: () => void;
};

const DataFrameCopyModal = (props: DataframeCopyModel) => {
	const [dataframes, setDataframes] = useState<TDataframe[]>([]);
	const [_isDataframeOpen, setIsDataframeOpen] = useState(false);
	const [createDisabled, setCreateDisabled] = useState(true);
	const [groupedDropdownItems, setGroupedDropdownItems] = useState<JSX.Element[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const { addToast } = useToast();
	const navigate = useNavigate();

	useMount(() => {
		setIsLoading(true);

		Dataframe.GetAll(['folder'])
			.then((res) => {
				setDataframes(res);

				const groupedDataframes = _.groupBy(res, 'folder.id');
				const dataframeFolderKeys = Object.keys(groupedDataframes);
				const dropdownHtml: JSX.Element[] = [];
				const dataframeFolders = res.map((x) => x.folder as TFolder);
				const kvpDataframes: GenericStringKeyValueResponse<string>[] =
					buildFolderDisplayForDropdown(dataframeFolderKeys, dataframeFolders);

				kvpDataframes.forEach((folderKey) => {
					let itemsByKey: TDataframe[] = [];
					const folder = dataframeFolders.find((x) => x?.id == +folderKey.key);
					let folderName = folder?.name;

					//condition for when chart does not belong to a folder
					if (!folderName) {
						folderName = 'Unassigned';
						itemsByKey = res.filter((x) => (x?.folder as TFolder) === null);
					} else {
						itemsByKey = res.filter(
							(x) => (x?.folder as TFolder)?.id === +folderKey.key
						);
					}

					dropdownHtml.push(
						<DropdownGroup
							label={`Folder: ${
								folderKey.value != '' ? folderKey.value : 'Unassigned'
							}`}
							key={folderKey.key}
						>
							{itemsByKey.map((item) => (
								<DropdownItem
									id={item.id?.toString()}
									key={item.name}
								>
									{item.name}
								</DropdownItem>
							))}
						</DropdownGroup>
					);
				});
				setGroupedDropdownItems(dropdownHtml);
			})
			.catch((e: Error) =>
				addToast(`Error fetching all dataframes, error: ${e.message}`, AlertVariant.danger)
			)
			.finally(() => {
				setIsLoading(false);
			});
	});

	const onSelectFrame = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	) => {
		if (item.id) {
			const frame = dataframes.find((_) => _.id === +(item.id ?? '0'));
			props.onSelectFrame(frame);
		} else {
			props.onSelectFrame(undefined);
		}
		setIsDataframeOpen(false);
		onFocusDataframes();
		setCreateDisabled(false);
	};

	const onFocusDataframes = () => {
		const element = document.getElementById('dataframe-selector');
		element?.focus();
	};

	const onClose = () => {
		setCreateDisabled(true);
		props.onSelectFrame(undefined);
		props.onClose();
	};

	const emptyTree = [BuildTreeViewItem({ id: -1, name: 'No Dataframes were found' }, [])];
	const treeViewData = dropdownToTreeViewData(groupedDropdownItems);

	const beforeCreate = () => {
		navigate(`/analyze/dataframes/edit/${props.selectedFrame?.id ?? 0}?isCopy=true`);
	};

	return (
		<React.Fragment>
			<Modal
				data-testid="add-dataframe-copy-modal"
				isOpen={props.isOpen}
				onClose={() => {
					onClose();
				}}
				variant="small"
				title="Add Dataframe"
				footer={
					<>
						{' '}
						<Button
							data-testid="create-button"
							key="create"
							variant="primary"
							onClick={beforeCreate}
							isDisabled={createDisabled}
						>
							Create
						</Button>
						<Button
							data-testid="cancel-button"
							key="cancel"
							variant="link"
							onClick={() => {
								onClose();
							}}
						>
							Cancel
						</Button>
					</>
				}
			>
				{isLoading ? (
					<Loader />
				) : (
					<>
						<div data-testid="dataframe-view">
							<FormGroup
								label="Dataframe"
								isRequired
								fieldId="dataframe"
							>
								<SearchableTreeViewSelect
									className="selectDataframe no-innerscroll"
									data-testid="dataframe-tree"
									data={dataframes.length > 0 ? treeViewData : emptyTree}
									placeHolderText={
										props.selectedFrame?.name ?? 'Select a Dataframe'
									}
									onSelect={onSelectFrame}
									treeItemsExpanded={true}
									isTopLvlSearchOnly={false}
								/>
							</FormGroup>
						</div>
					</>
				)}
			</Modal>
		</React.Fragment>
	);
};

export default DataFrameCopyModal;
