import axiosConfig from '../axiosConfig';
import { DimensionValueListResponse, GenericKeyValueResponse } from '../types';
import { TDimension, TDimensionValue } from './Dimension';

export type TDimensionAttribute = {
	code: string;
	created_at: number;
	creator: unknown;
	dimension: TDimension | number;
	disabled: boolean;
	display_attribute: boolean;
	effective_dated: boolean;
	name: string;
	sequence: number;
	show_on_summary: boolean;
	type: string;
	id: number;
	isDimension?: boolean;
	uuid?: string;
};

export type TNewDimensionAttribute = Omit<
	TDimensionAttribute,
	'id' | 'creator' | 'updater' | 'updated_at' | 'created_at'
>;

export class DimensionAttribute implements TDimensionAttribute {
	code: string;
	created_at: number;
	creator: unknown;
	dimension: number | TDimension;
	disabled: boolean;
	display_attribute: boolean;
	effective_dated: boolean;
	name: string;
	sequence: number;
	show_on_summary: boolean;
	type: string;
	id: number;
	uuid?: string;

	constructor(dimensionAttribute: TDimensionAttribute) {
		this.code = dimensionAttribute.code;
		this.created_at = dimensionAttribute.created_at;
		this.creator = dimensionAttribute.creator;
		this.dimension = dimensionAttribute.dimension;
		this.disabled = dimensionAttribute.disabled;
		this.display_attribute = dimensionAttribute.display_attribute;
		this.effective_dated = dimensionAttribute.effective_dated;
		this.name = dimensionAttribute.name;
		this.sequence = dimensionAttribute.sequence;
		this.show_on_summary = dimensionAttribute.show_on_summary;
		this.type = dimensionAttribute.type;
		this.id = dimensionAttribute.id;
		this.uuid = dimensionAttribute.uuid;
	}

	public static New(
		dimensionAttribute: TNewDimensionAttribute,
		expand: (keyof TDimensionAttribute)[] = []
	): Promise<TDimensionAttribute> {
		return axiosConfig.instance
			.post('app/dimension-attribute', dimensionAttribute, { params: { expand: expand } })
			.then((res) => {
				return new DimensionAttribute(res.data as TDimensionAttribute);
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/dimension-attribute/${id}`).then(() => {
			return true;
		});
	}

	public static Update(
		dimensionAttribute: TDimensionAttribute,
		expand: (keyof TDimensionAttribute)[] = []
	): Promise<TDimensionAttribute> {
		return axiosConfig.instance
			.patch('app/dimension-attribute', dimensionAttribute, { params: { expand: expand } })
			.then((res) => {
				return res.data as TDimensionAttribute;
			});
	}

	public static GetAll(
		expand: (keyof TDimensionAttribute)[] = []
	): Promise<DimensionAttribute[]> {
		return axiosConfig.instance
			.get('app/dimension-attributes', { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TDimensionAttribute[]).map(
					(dimensionAttribute: TDimensionAttribute) => {
						return new DimensionAttribute(dimensionAttribute);
					}
				);
			});
	}

	public static Retrieval(id: number): Promise<GenericKeyValueResponse<string>[]> {
		return axiosConfig.instance
			.get<GenericKeyValueResponse<string>[]>(`app/data-retrieval/dimension-attribute/${id}`)
			.then((res) => {
				return res.data || [];
			});
	}

	public static async RetrievalAsync(id: number): Promise<GenericKeyValueResponse<string>[]> {
		try {
			const res = await axiosConfig.instance.get<GenericKeyValueResponse<string>[]>(
				`app/data-retrieval/dimension-attribute/${id}`
			);
			return res.data || [];
		} catch (error) {
			// Handle errors here if needed
			console.error(error);
			return [];
		}
	}

	public static GetValues(
		id: number,
		pageNumber: number,
		perPage: number,
		searchTerm: string
	): Promise<DimensionValueListResponse> {
		return axiosConfig.instance
			.get<DimensionValueListResponse>(
				`/app/dimension-attribute-values/${id}?current_page=${pageNumber}&page_size=${perPage}&search_term=${searchTerm}`
			)
			.then((res) => {
				return res.data || [];
			});
	}

	public static UpdateValues(
		id: number,
		values: TDimensionValue[]
	): Promise<GenericKeyValueResponse<string>[]> {
		return axiosConfig.instance
			.post<GenericKeyValueResponse<string>[]>(
				`/app/dimension-attribute-values/${id}`,
				values
			)
			.then((res) => {
				return res.data || [];
			});
	}

	public static DeleteValue(dimId: number, valueId: number): Promise<boolean> {
		return axiosConfig.instance
			.delete(`app/dimension-attribute-values/${dimId}/${valueId}`)
			.then(() => {
				return true;
			});
	}

	public static Default(): TNewDimensionAttribute {
		return {
			code: '',
			disabled: false,
			display_attribute: false,
			effective_dated: false,
			name: '',
			sequence: 0,
			show_on_summary: false,
			type: '',
			dimension: 0,
		};
	}
}
