import React, { Dispatch, SetStateAction, useState } from 'react';
import { AlertVariant, FormGroup, TreeViewDataItem } from '@patternfly/react-core';
import { useMount } from 'react-use';
import { TDataframe } from '../../../api/types';
import { Dataframe } from '../../../api/dataframes/Dataframes';
import Loader from '../../util/Loader';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import SearchableTreeViewSelect from '../../form/Select/SearchableTreeViewSelect';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { Folder } from '../../../api/folders/Folder';
import { FolderTypesEnum } from '../../../enums/folder-types-enum';
import { TFolder } from '../../../api/foundational-elements/Folder';

export type DataframeChartOptionsViewProps = {
	selectedFrame?: TDataframe;
	onSelectFrame: Dispatch<SetStateAction<TDataframe | undefined>>;
};

const DataFrameChartOptionsView = (props: DataframeChartOptionsViewProps) => {
	const [dataframes, setDataframes] = useState<TDataframe[]>([]);
	const [_isDataframeOpen, setIsDataframeOpen] = useState(false);
	const [folders, setFolders] = useState<TFolder[]>([]);
	const [selectedFolder, setSelectedFolder] = useState<TreeViewDataItem[]>([]);

	const [isLoading, setIsLoading] = useState(false);
	const { addToast } = useToast();

	useMount(() => {
		setIsLoading(true);

		void Folder.GetAll().then((response) => {
			if (response && response.length > 0) {
				const filteredFolders = response.filter((f) => f.type === FolderTypesEnum.Charts);
				setFolders(filteredFolders as TFolder[]);

				const ls_prevSelectedFolderId = localStorage.getItem('currentSelectedFolderId');
				const prevSelectedFolderId = parseInt(ls_prevSelectedFolderId ?? '0');

				if (prevSelectedFolderId > 0) {
					const actualFolder = filteredFolders.find((x) => x.id == prevSelectedFolderId);

					if (actualFolder) {
						const folder: TreeViewDataItem = {
							name: actualFolder?.name,
							id: actualFolder?.id.toString(),
						};
						setSelectedFolder([folder]);
					}
				}
			} else {
				setFolders([
					{
						id: -1,
						name: 'No folders found',
						type: 'dataframes',
						items: [],
					},
				]);
			}
		});
		Dataframe.GetAll()
			.then((res) => {
				setDataframes(res);
			})
			.catch((e: Error) =>
				addToast(`Error fetching all dataframes, error: ${e.message}`, AlertVariant.danger)
			)
			.finally(() => {
				setIsLoading(false);
			});
	});

	const onSelectFrame = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	) => {
		if (item.id) {
			const frame = dataframes.find((_) => _.id === +(item.id ?? '0'));
			props.onSelectFrame(frame);
		} else {
			props.onSelectFrame(undefined);
		}
		setIsDataframeOpen(false);
		onFocusDataframes();
	};

	const onSelectFolder = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	) => {
		localStorage.setItem('createChartFolderId', item.id!);
	};

	const onFocusDataframes = () => {
		const element = document.getElementById('dataframe-selector');
		element?.focus();
	};

	const emptyTree = [BuildTreeViewItem({ id: -1, name: 'No Dataframes were found' }, [])];

	return isLoading ? (
		<Loader />
	) : (
		<div data-testid="dataframe-view">
			<FormGroup
				label="Dataframe"
				isRequired
				fieldId="dataframe"
			>
				<SearchableTreeViewSelect
					className="selectDataframe no-innerscroll"
					data-testid="dataframe-tree"
					data={
						dataframes.length > 0
							? dataframes.map((km) => BuildTreeViewItem(km, []))
							: emptyTree
					}
					placeHolderText={props.selectedFrame?.name ?? 'Select a Dataframe'}
					onSelect={onSelectFrame}
					treeItemsExpanded={true}
					isTopLvlSearchOnly={true}
				/>
			</FormGroup>
			<span className="spacer-xs"></span>
			<FormGroup
				label="Folder"
				fieldId="folder"
			>
				<SearchableTreeViewSelect
					className="no-innerscroll"
					data-testid="folder-tree"
					data={folders ? folders.map((_: TFolder) => BuildTreeViewItem(_, _.items)) : []}
					placeHolderText={'Select a Folder'}
					onSelect={onSelectFolder}
					treeItemsExpanded={true}
					isTopLvlSearchOnly={true}
					selectedItems={selectedFolder}
				/>
			</FormGroup>
		</div>
	);
};

export default DataFrameChartOptionsView;
