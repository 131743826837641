import ListCards from '../../components/card/ListCards';
import {
	faDisplayChartUpCircleCurrency,
	faFileChartColumn,
} from '@fortawesome/pro-light-svg-icons';
import { faFolderBlank } from '@fortawesome/pro-solid-svg-icons';
import FilterIcon from '@fortawesome/fontawesome-pro/svgs/regular/filter.svg';
import { ChartIconMap } from '../../helpers/helper-components/chart-helper';
import { CardProps } from '../../types/cards/card-props';
import { CardTypesEnum } from '../../enums/card-types.enum';
import { ChartTypesEnum } from '../../enums/chart-types.enum';
import { Card } from '../../api/cards/Cards';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import {
	AlertVariant,
	Flex,
	FlexItem,
	Grid,
	GridItem,
	TextInput,
	Title,
} from '@patternfly/react-core';
import { Bullseye, Spinner } from '@patternfly/react-core';
import EmptyResults from '../../components/EmptyResults';
import { faInfoCircle, faPresentation, faTable } from '@fortawesome/pro-regular-svg-icons';
import EntityMiscButtons from './EntityMiscButtons';
import { CardActionsEnum } from '../../enums/card-actions.enum';
import { useUser } from '../../components/user/ApplicationProvider';
import { TUser } from '../../api/security/User';
import { SharedEntity } from '../../api/shared-entity/SharedEntity';
import { SharedEntityUser, TSharedEntityUser } from '../../api/shared-entity/SharedEntityUser';
import { FolderTypesEnum } from '../../enums/folder-types-enum';

type Props = {
	listType: CardTypesEnum;
};

function ListChildCards(props: Props) {
	const { parentFolderName, parentFolderId, folderId } = useParams();
	const { addToast } = useToast();
	const [filter, setFilter] = useState<string>('');
	const [loading, setIsLoading] = useState(false);
	const [cards, setCards] = useState<CardProps[]>([]);
	const [filteredCards, setFilteredCards] = useState<CardProps[]>([]);
	const pathname = useLocation().pathname;
	const segments = pathname.split('/');
	const lastSegment = segments[segments.length - 1];
	const secondLastSegment = segments[segments.length - 2];
	const currentUser = useUser();
	const [userSharedPermisson, setUserSharedPermission] = useState<TSharedEntityUser>(
		SharedEntityUser.Default()
	);
	const panelTitle = parentFolderName ?? decodeURIComponent(secondLastSegment);

	useEffect(() => {
		let currentFolderId = lastSegment;
		if (parentFolderId) {
			currentFolderId = parentFolderId;
		} else if (folderId) {
			currentFolderId = folderId;
		}
		if (!isNaN(parseInt(currentFolderId))) {
			SharedEntity.GetPermissionForEntity('folder', parseInt(currentFolderId), currentUser.id)
				.then((response) => {
					setUserSharedPermission(response);
				})
				.catch(() => {
					console.log('Failed getting use shared permission');
				});
		}
	}, []);

	const getCardUrl = (card: Card) => {
		let url = `/${card.type}/${card.id}`;
		if (card.isTable) {
			url = `/table/view/${card.id}`;
		} else if (!card.isFolder) {
			switch (card.type) {
				case CardTypesEnum.Chart:
					url = `/analyze/${CardTypesEnum.Chart}/view/chart/${card.id}`;
					break;
				case CardTypesEnum.Dashboard:
					url = `/analyze/dashboards/view/${card.id}`;
					break;
				case CardTypesEnum.Report:
					url = `/report/view/${card.id}`;
					break;
				case CardTypesEnum.Presentation:
					url = `/present/edit/${card.id}`;
					break;
				default:
					break;
			}
		} else {
			switch (props.listType) {
				case CardTypesEnum.Chart:
					if (pathname.includes('/charts/folder/')) {
						url = pathname + `/folder/${card.name}/${card.id}`;
					} else {
						url = `/analyze/${CardTypesEnum.Chart}/folder/${card.name}/${card.id}`;
					}
					break;
				case CardTypesEnum.Dashboard:
					url = `/analyze/folder/${card.name}/${card.id}`;
					break;
				case CardTypesEnum.Report:
					if (pathname.includes('/folder/')) {
						url = pathname + `/folder/${card.name}/${card.id}`;
					} else {
						url = `/report/folder/${card.name}/${card.id}`;
					}
					break;
				case CardTypesEnum.Folder:
					if (pathname.includes('/folder/')) {
						url = pathname + `/folder/${card.name}/${card.id}`;
					} else {
						url = `/folder/${card.name}/${card.id}`;
					}
					break;
				case CardTypesEnum.Table:
					if (pathname.includes('/table/folder/')) {
						url = pathname + `/folder/${card.name}/${card.id}`;
					} else {
						url = `/analyze/table/folder/${card.name}/${card.id}`;
					}
					break;
				case CardTypesEnum.Presentation:
					if (pathname.includes('/folder/')) {
						url = pathname + `/folder/${card.name}/${card.id}`;
					} else {
						url = `/present/folder/${card.name}/${card.id}`;
					}
					break;
				default:
					break;
			}
		}
		return url;
	};

	const getCardIcon = (card: Card) => {
		let icon = faFileChartColumn;
		if (card.isFolder) {
			icon = faFolderBlank;
		} else if (card.chartType && card.type == CardTypesEnum.Chart) {
			icon = ChartIconMap[card.chartType as ChartTypesEnum];
		} else if (card.type == CardTypesEnum.Dashboard) {
			icon = faDisplayChartUpCircleCurrency;
		} else if (card.type == CardTypesEnum.Presentation) {
			icon = faPresentation;
		} else if (card.isTable) {
			icon = faTable;
		}

		return icon;
	};

	const getCardActions = (type: CardTypesEnum) => {
		switch (type) {
			case CardTypesEnum.Analyze:
				return [];
			case CardTypesEnum.Dashboard:
				return [CardActionsEnum.Delete];
			case CardTypesEnum.DataFrame:
				return [];
			case CardTypesEnum.Favorite:
				return [];
			case CardTypesEnum.Chart:
				return [CardActionsEnum.Delete];
			case CardTypesEnum.Folder:
				return [];
			case CardTypesEnum.Presentation:
				return [];
			case CardTypesEnum.Report:
				return [];
		}
	};

	const GetApiCardType = () => {
		switch (props.listType) {
			case CardTypesEnum.Folder:
				return CardTypesEnum.Dashboard;
			case CardTypesEnum.Table:
				return pathname == '/analyze/tables' || pathname.includes('/analyze/table/')
					? FolderTypesEnum.Tables
					: CardTypesEnum.Dashboard;
			default:
				return props.listType;
		}
	};

	useEffect(() => {
		fetchCards();
	}, [props.listType, parentFolderId, pathname]);

	const fetchCards = (): void => {
		setIsLoading(true);
		setFilter('');
		const type = GetApiCardType();

		let actualParentFolderId = 0;
		if (!parentFolderId) {
			actualParentFolderId = parseInt(lastSegment);
		} else {
			actualParentFolderId = parseInt(parentFolderId);
		}

		Card.GetAll(type, actualParentFolderId, ['creator'])
			.then((cards) => {
				let listCards: CardProps[] = [];
				if (cards) {
					listCards = cards
						.sort((a, b) => {
							return Number(b.isFolder) - Number(a.isFolder);
						})
						.map((c) => {
							return {
								id: c.id,
								url: getCardUrl(c),
								title: c.name,
								type: c.isTable ? CardTypesEnum.Table : (c.type as CardTypesEnum),
								isClustered:
									c.chartType === ChartTypesEnum.Clusteredbar ||
									c.chartType === ChartTypesEnum.Clusteredcolumn ||
									c.chartType === ChartTypesEnum.Clusteredline,
								icon: getCardIcon(c),
								isShared: (c.creator as TUser).id !== currentUser.id,
								ownerName: (c.creator as TUser).name,
								isDashboard: c.type == CardTypesEnum.Dashboard,
								actions: getCardActions(c.type as CardTypesEnum),
								refreshList: fetchCards,
							};
						});
				}

				setCards(listCards);
				setFilteredCards(listCards);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load cards. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	function filterCards(value: string) {
		const filteredCards = cards.filter((c) =>
			c.title.toLowerCase().includes(value.toLowerCase())
		);

		setFilteredCards(filteredCards);
		setFilter(value);
	}

	const header = (
		<>
			<span className="spacer-sm"></span>
			<Grid span={12}>
				<GridItem span={10}>
					<Flex direction={{ default: 'row' }}>
						<FlexItem>
							<Title headingLevel={'h1'}>
								{panelTitle && panelTitle != 'analyze'
									? panelTitle
									: props.listType?.charAt(0)?.toUpperCase() +
									  props.listType?.slice(1)}
							</Title>
						</FlexItem>
					</Flex>
				</GridItem>
				<GridItem span={2}>
					{(parentFolderId || folderId || lastSegment) && (
						<EntityMiscButtons
							entityType={'folder'}
							entityName={
								parentFolderName
									? parentFolderName
									: props.listType?.charAt(0)?.toUpperCase() +
									  props.listType?.slice(1)
							}
							canShare={userSharedPermisson.can_share}
							isView={true}
						/>
					)}
				</GridItem>
			</Grid>
			<GridItem span={3}>
				<TextInput
					value={filter}
					type="text"
					className="filter"
					customIconUrl={FilterIcon}
					placeholder="Filter"
					onChange={(value) => filterCards(value)}
					aria-label={`Filter ${props.listType}`}
				/>
			</GridItem>
		</>
	);

	if (loading) {
		return (
			<Bullseye>
				<div>
					<Spinner
						isSVG
						size={'xl'}
					/>
				</div>
			</Bullseye>
		);
	} else {
		return filteredCards.length > 0 ? (
			<>
				<Grid hasGutter>
					{header}
					<GridItem span={12}>
						<ListCards
							items={filteredCards}
							hidePin={true}
						/>
					</GridItem>
				</Grid>
			</>
		) : (
			<>
				<Grid hasGutter>
					{header}
					<GridItem span={12}>
						<EmptyResults
							emptyStateBody=""
							iconName={faInfoCircle}
							backComponent={<></>}
						/>
					</GridItem>
				</Grid>
			</>
		);
	}
}

export default ListChildCards;
