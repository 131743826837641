export function capitalizeFirstLetter(string: string): string {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeAllWords(string: string): string {
	return string
		.split(' ')
		.map((word) => capitalizeFirstLetter(word))
		.join(' ');
}

export function cleanupPropertyName(string: string): string {
	return capitalizeAllWords(string.replace(/_/g, ' '));
}

export function dateToYYYYMMDDHHMMSS(date: Date, timeZone = 'UTC'): string {
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: false,
		timeZone: timeZone,
	};

	const formattedDate = new Intl.DateTimeFormat('en-US', options).formatToParts(date);

	const year = formattedDate.find((part) => part.type === 'year')?.value ?? '';
	const month = formattedDate.find((part) => part.type === 'month')?.value ?? '';
	const day = formattedDate.find((part) => part.type === 'day')?.value ?? '';
	const hour = formattedDate.find((part) => part.type === 'hour')?.value ?? '';
	const minute = formattedDate.find((part) => part.type === 'minute')?.value ?? '';
	const second = formattedDate.find((part) => part.type === 'second')?.value ?? '';

	return `${year}${month}${day}${hour}${minute}${second}`;
}

export function timestampToMMDDYYYY(timestamp: number): string {
	const actualSystemTimezone = getUserTimeZone();

	const date = new Date(timestamp * 1000);
	let formattedDate = new Intl.DateTimeFormat('en-US', {
		timeZone: actualSystemTimezone,
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}).format(date);

	formattedDate = formattedDate.replace(/\//g, '-');

	return formattedDate;
}

export const removeTimeFromUnixTimestamp = (timestamp: number): number => {
	// Convert to milliseconds, round down to the start of the day, and convert back to seconds
	return Math.floor(timestamp / 86400) * 86400;
};

export function timestampToHHDDAMPM(timestamp: number): string {
	const date = new Date(timestamp * 1000);

	// Format the time part
	const formattedTime = new Intl.DateTimeFormat('en-US', {
		timeZone: 'America/New_York',
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	}).format(date);

	return formattedTime;
}

export function toISO8601(date: string | Date): string {
	const z = (n: number): string => (n < 10 ? `0${n}` : `${n}`);
	const offset = (n: number): string => (n < 0 ? `-${z(n * -1)}` : `+${z(n)}`);
	const c = new Date(date);
	return `${c.getUTCFullYear()}-${z(c.getUTCMonth() + 1)}-${z(c.getUTCDate())}T${z(
		c.getUTCHours()
	)}:${z(c.getUTCMinutes())}:${z(c.getUTCSeconds())}${offset(c.getTimezoneOffset() / 60)}:00`;
}

export function isValidDatetime(str: string) {
	const date = new Date(str);

	// Check if the date object is valid
	return !isNaN(date.getTime());
}

export function isValidDate(dateString: string, format = 'MM-dd-yyyy'): boolean {
	const [monthIndex, dayIndex, yearIndex] = format.split('-').map((part) => {
		if (part === 'MM') return 0;
		if (part === 'dd') return 1;
		if (part === 'yyyy') return 2;
		return -1;
	});

	const parts = dateString.split('-');
	if (parts.length !== 3) return false;

	const year = parseInt(parts[yearIndex], 10);
	const month = parseInt(parts[monthIndex], 10);
	const day = parseInt(parts[dayIndex], 10);

	if (isNaN(year) || isNaN(month) || isNaN(day)) return false;

	// Validate year, month, and day ranges
	if (year < 1 || month < 1 || month > 12 || day < 1) return false;

	const daysInMonth = new Date(year, month, 0).getDate(); // Get the number of days in the month
	return day <= daysInMonth;
}

export function currentTimestampZeroed() {
	const date = new Date(Date.now());
	date.setHours(0);
	date.setMinutes(0);
	date.setSeconds(0);

	return Math.floor(date.getTime() / 1000.0);
}

export function currentTimestamp() {
	return Math.floor(Date.now() / 1000.0);
}

export function timeAgo(timestamp: number): string {
	const now = new Date().getTime();
	const createdDate = new Date(timestamp * 1000); // assuming the timestamp is in seconds
	const seconds = Math.floor((now - createdDate.getTime()) / 1000);

	let interval = seconds / 31536000; // years

	if (interval > 1) {
		const outInterval = Math.floor(interval);

		return outInterval === 1 ? '1 year ago' : `${outInterval} years ago`;
	}

	interval = seconds / 2592000; // months
	if (interval > 1) {
		const outInterval = Math.floor(interval);

		return outInterval === 1 ? '1 month ago' : `${outInterval} months ago`;
	}

	interval = seconds / 86400; // days
	if (interval > 1) {
		const outInterval = Math.floor(interval);

		return outInterval === 1 ? '1 day ago' : `${outInterval} days ago`;
	}

	interval = seconds / 3600; // hours
	if (interval > 1) {
		const outInterval = Math.floor(interval);

		return outInterval === 1 ? '1 hour ago' : `${outInterval} hours ago`;
	}

	interval = seconds / 60; // minutes
	if (interval > 1) {
		const outInterval = Math.floor(interval);

		return outInterval === 1 ? '1 minute ago' : `${outInterval} minutes ago`;
	}
	return 'Just now';
}

export function getUserTimeZone(): string {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getTodayDateFormatted(): string {
	const today = new Date();
	const day = String(today.getDate()).padStart(2, '0'); // Get the day and pad to 2 digits
	const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
	const year = today.getFullYear();

	return `${day}/${month}/${year}`;
}
