import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, FlexItem, Grid, GridItem, Text, Tooltip, Truncate } from '@patternfly/react-core';
import React, { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import DraggableWidget from './DraggableWidget';
import Loader from '../../util/Loader';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useParams } from 'react-router-dom';
import { faFolder, faTable } from '@fortawesome/pro-solid-svg-icons';
import { Card, TCard } from '../../../api/cards/Cards';
import ZiCreateSelectionModal, {
	ViewTypesEnum,
	ZiCreateSelectionOptionsModalProps,
} from '../../modals/shared/creation-selection/ZiCreateSelectionModal';
import { faChartLineUp, faDatabase } from '@fortawesome/pro-regular-svg-icons';
import { FolderTypesEnum } from '../../../enums/folder-types-enum';

type Props = {
	searchTerm?: string;
};

const TablesWidgets = (props: Props) => {
	const { searchTerm } = props;
	const { dashboardId, presentationId } = useParams();
	const [showAll, setShowAll] = useState<boolean>(false);
	const [cards, setCards] = useState<TCard[]>([]);
	const [displayedCards, setDisplayedCards] = useState<TCard[]>([]);
	const [selectedFolder, setSelectedFolder] = useState<TCard>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const defaultVisibleCount = 3;

	useMount(() => {
		getData();
	});

	useEffect(() => {
		let newDisplayedCards = cards;
		if (searchTerm) {
			newDisplayedCards = newDisplayedCards.filter(
				(card) => card.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
			);
		}
		if (!showAll) {
			newDisplayedCards = newDisplayedCards.slice(0, defaultVisibleCount);
		}

		setDisplayedCards(newDisplayedCards);
	}, [searchTerm, showAll]);

	const getData = (folderId?: number) => {
		setIsLoading(true);
		Card.GetAll(FolderTypesEnum.Tables, folderId)
			.then((response) => {
				if (response) {
					const sortedResponse = response.sort((a, b) =>
						a.isFolder === b.isFolder ? 0 : a.isFolder ? -1 : 1
					);
					setCards(sortedResponse);
					setDisplayedCards(sortedResponse.slice(0, defaultVisibleCount));
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getData(selectedFolder?.id);
	}, [selectedFolder]);

	const handleFolderClick = (folderId: number) => {
		setSelectedFolder(cards.find((c) => c.isFolder && c.id === folderId));
	};

	const handleCreateWidgetClick = () => {
		handleDashboardModalToggle();
	};

	const handleDashboardModalToggle = () => {
		setIsModalOpen((prev) => !prev);
	};

	const goToTopLevelTables = () => {
		setShowAll(!showAll);
		setSelectedFolder(undefined);
	};

	if (searchTerm && displayedCards.length === 0) {
		return <></>;
	}

	const optionsModalProps: ZiCreateSelectionOptionsModalProps = {
		isOpen: isModalOpen,
		onClose: handleDashboardModalToggle,
		dashboardId: dashboardId ? parseInt(dashboardId) : undefined,
		presentationId: presentationId ? parseInt(presentationId) : undefined,
		defaultView: ViewTypesEnum.Default,
		title: 'Add New Table',
		urlBase: dashboardId
			? `/analyze/dashboards/${dashboardId}/table/create`
			: `/present/${presentationId ?? 0}/table/create`,
		viewOptions: [
			{
				type: ViewTypesEnum.Measure,
				icon: faChartLineUp,
				label: 'Key Measures',
			},
			{
				type: ViewTypesEnum.Dataframe,
				icon: faDatabase,
				label: 'Dataframe',
			},
		],
		navigateOnModalClose: true,
	};

	return (
		<>
			<Grid span={12}>
				<Flex>
					<FlexItem>
						{selectedFolder ? (
							<Text
								className="assets-title"
								style={{ display: 'flex' }}
							>
								<FlexItem
									onClick={() => goToTopLevelTables()}
									component={'a'}
								>
									Tables
								</FlexItem>
								<Text style={{ paddingLeft: '0.275rem' }}>
									/ {selectedFolder.name}
								</Text>
							</Text>
						) : (
							<Text className="assets-title">Tables</Text>
						)}
					</FlexItem>
					{cards.length > defaultVisibleCount && (
						<FlexItem
							align={{ default: 'alignRight' }}
							onClick={() => setShowAll(!showAll)}
							component={'a'}
						>
							{showAll ? 'See Less' : 'See All'}
						</FlexItem>
					)}
				</Flex>
			</Grid>
			<Grid hasGutter>
				<GridItem span={6}>
					<div
						className="droppable-element no-drag"
						onClick={handleCreateWidgetClick}
					>
						<Flex
							justifyContent={{ default: 'justifyContentCenter' }}
							style={{ padding: '0.5rem' }}
						>
							<Flex
								fullWidth={{ default: 'fullWidth' }}
								justifyContent={{ default: 'justifyContentCenter' }}
							>
								<Tooltip content={'New Table'}>
									<FlexItem>
										<FontAwesomeIcon
											icon={faPlus}
											size="3x"
										/>
									</FlexItem>
								</Tooltip>
							</Flex>
							<Flex justifyContent={{ default: 'justifyContentCenter' }}>
								<Text component="p">
									<Truncate content={'New Table'} />
								</Text>
							</Flex>
						</Flex>
					</div>
				</GridItem>
				{isLoading ? (
					<Loader />
				) : (
					<>
						{displayedCards.map((card, index) => (
							<GridItem
								span={6}
								key={index}
							>
								{card.isFolder ? (
									<div
										className="droppable-element no-drag"
										onClick={() => handleFolderClick(card.id)}
									>
										<Flex
											justifyContent={{ default: 'justifyContentCenter' }}
											style={{ padding: '0.5rem' }}
										>
											<Flex
												fullWidth={{ default: 'fullWidth' }}
												justifyContent={{
													default: 'justifyContentCenter',
												}}
											>
												<Tooltip content={card.name}>
													<FlexItem>
														<FontAwesomeIcon
															icon={faFolder}
															size="3x"
														/>
													</FlexItem>
												</Tooltip>
											</Flex>
											<Flex
												justifyContent={{
													default: 'justifyContentCenter',
												}}
											>
												<Text component="p">
													<Truncate content={card.name} />
												</Text>
											</Flex>
										</Flex>
									</div>
								) : (
									<DraggableWidget
										defaultWidget={{
											col: 0,
											row: 0,
											name: card.name,
											content: card.name,
											sizex: 6,
											sizey: 6,
											widget_type: 'pivot_table',
											report: card.id,
											page: 0,
										}}
									>
										<Flex
											justifyContent={{ default: 'justifyContentCenter' }}
											style={{ padding: '0.5rem' }}
										>
											<Flex
												fullWidth={{ default: 'fullWidth' }}
												justifyContent={{
													default: 'justifyContentCenter',
												}}
											>
												<Tooltip content={card.name}>
													<FlexItem>
														<FontAwesomeIcon
															icon={faTable}
															size="3x"
														/>
													</FlexItem>
												</Tooltip>
											</Flex>
											<Flex
												justifyContent={{
													default: 'justifyContentCenter',
												}}
											>
												<Text component="p">
													<Truncate content={card.name} />
												</Text>
											</Flex>
										</Flex>
									</DraggableWidget>
								)}
							</GridItem>
						))}
					</>
				)}
			</Grid>
			<ZiCreateSelectionModal {...optionsModalProps} />
		</>
	);
};

export default TablesWidgets;
