import React, { Dispatch, SetStateAction } from 'react';
import { ChartCreationOptions } from './ZiChartOptionsModal';
import { Button } from '@patternfly/react-core';
import './DefaultChartOptionsView.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLineUp, faCopy, faDatabase } from '@fortawesome/pro-light-svg-icons';
import { TFolder } from '../../../api/foundational-elements/Folder';

export type DefaultChartOptionsViewProps = {
	onViewChange: Dispatch<SetStateAction<ChartCreationOptions>>;
	selectedFolder?: TFolder;
	onSelectFolder: Dispatch<SetStateAction<TFolder | undefined>>;
};

const DefaultChartOptionsView = (props: DefaultChartOptionsViewProps) => {
	const changeView = (type: ChartCreationOptions) => {
		props.onViewChange(type);
	};

	return (
		<div
			className="component-container"
			data-testid="default-view"
		>
			<span>Create chart based on</span>
			<div className="chart-options-list">
				<Button
					className="chart-option"
					onClick={() => changeView('measure')}
					variant="link"
					data-testid="select-measure"
				>
					<span className="chart-option-icon">
						<FontAwesomeIcon icon={faChartLineUp} />
					</span>
					Key Measure
				</Button>
				<Button
					className="chart-option"
					onClick={() => changeView('dataframe')}
					variant="link"
					data-testid="select-frame"
				>
					<span className="chart-option-icon">
						<FontAwesomeIcon icon={faDatabase} />
					</span>
					Dataframe
				</Button>
				<Button
					className="chart-option"
					onClick={() => changeView('copy')}
					variant="link"
					data-testid="select-copy"
				>
					<span className="chart-option-icon">
						<FontAwesomeIcon icon={faCopy} />
					</span>
					Copy existing chart
				</Button>
			</div>
		</div>
	);
};

export default DefaultChartOptionsView;
