import React, { Dispatch, SetStateAction, useState } from 'react';
import { useMount } from 'react-use';
import { DropdownGroup, DropdownItem, FormGroup, TreeViewDataItem } from '@patternfly/react-core';
import { BuildTreeViewItem } from '../../../../helpers/tree-view.helper';
import Loader from '../../../util/Loader';
import { Report } from '../../../../api/reports/Reports';
import { GenericStringKeyValueResponse, TReport } from '../../../../api/types';
import SearchableTreeViewSelect, {
	dropdownToTreeViewData,
} from '../../../form/Select/SearchableTreeViewSelect';
import { TFolder } from '../../../../api/types/folder';
import _ from 'lodash';
import { buildFolderDisplayForDropdown } from '../../../../helpers/dropdown-folder.helper';

export type CopyViewProps = {
	onSelectItem: Dispatch<SetStateAction<any>>;
	isTable: boolean;
};

const CopyView = (props: CopyViewProps) => {
	const [filteredItems, setFilteredItems] = useState<TreeViewDataItem[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedReportName, setSelectedReportName] = useState<string>();
	const [reports, setReports] = useState<TReport[]>([]);
	const [groupedDropdownItems, setGroupedDropdownItems] = useState<JSX.Element[]>([]);

	useMount(() => {
		setIsLoading(true);

		void Report.GetAll(['folder'], props.isTable).then((response) => {
			const responseItems: TReport[] = response;
			setReports(responseItems);

			const groupedReports = _.groupBy(response, 'folder.id');
			const reportFolderKeys = Object.keys(groupedReports);
			const dropdownHtml: JSX.Element[] = [];
			const reportFolders = response.map((x) => x.folder as TFolder);
			const kvpReports: GenericStringKeyValueResponse<string>[] =
				buildFolderDisplayForDropdown(reportFolderKeys, reportFolders);

			kvpReports.forEach((folderKey) => {
				let itemsByKey: TReport[] = [];
				const folder = reportFolders.find((x) => x?.id == +folderKey.key);
				let folderName = folder?.name;

				//condition for when chart does not belong to a folder
				if (!folderName) {
					folderName = 'Unassigned';
					itemsByKey = response.filter((x) => (x?.folder as TFolder) === null);
				} else {
					itemsByKey = response.filter(
						(x) => (x?.folder as TFolder)?.id === +folderKey.key
					);
				}

				dropdownHtml.push(
					<DropdownGroup
						label={`Folder: ${folderKey.value != '' ? folderKey.value : 'Unassigned'}`}
						key={folderKey.key}
					>
						{itemsByKey.map((item) => (
							<DropdownItem
								id={item.id?.toString()}
								key={item.name}
							>
								{item.name}
							</DropdownItem>
						))}
					</DropdownGroup>
				);
			});
			setGroupedDropdownItems(dropdownHtml);

			const parsedItems: { id: number; name: string; display_name?: string | null }[] = [];

			responseItems.map((report) => {
				const parsedItem: { id: number; name: string; display_name?: string | null } = {
					id: report.id ?? 0,
					name: report.name,
				};
				parsedItems.push(parsedItem);
			});

			setFilteredItems(parsedItems.map((_) => BuildTreeViewItem(_, [])));
			setIsLoading(false);
		});
	});

	const selectReport = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	): void => {
		const reportOrTableId = item.id?.toString() ?? '';
		const chosenItem = filteredItems.find((x) => x.id == reportOrTableId);

		props.onSelectItem(chosenItem);
		setSelectedReportName(item.name?.toString());
	};

	const emptyTree = [
		BuildTreeViewItem(
			{ id: -1, name: `No ${!props.isTable ? 'Reports' : 'Tables'} were found` },
			[]
		),
	];
	const treeViewData = dropdownToTreeViewData(groupedDropdownItems);

	return isLoading ? (
		<Loader />
	) : (
		<div data-testid="copy-view">
			<FormGroup>
				<SearchableTreeViewSelect
					className="no-innerscroll"
					data-testid="report-tree"
					data={filteredItems && filteredItems.length > 0 ? treeViewData : emptyTree}
					placeHolderText={
						selectedReportName ?? `Search for a ${!props.isTable ? 'Report' : 'Table'}`
					}
					onSelect={selectReport}
					treeItemsExpanded={true}
					isTopLvlSearchOnly={false}
				/>
			</FormGroup>
		</div>
	);
};

export default CopyView;
