import axiosConfig from '../axiosConfig';
import { TUser } from '../security/User';
import { TSharedEntity } from '../shared-entity/SharedEntity';
import { TReport, TReportConditionalRules } from '../types';

export type TNewReport = Omit<
	TReport,
	'id' | 'updated_at' | 'updater' | 'created_at' | 'creator' | 'sharedReport'
>;

export class Report implements TReport {
	id: number;
	name: string;
	description?: string;
	folder?: number;
	dataframe: number;
	conditionalRules: TReportConditionalRules[];
	updated_at: string;
	updater: number;
	created_at: string;
	creator: string;
	is_from_dataframe: boolean;
	owner: TUser | number;
	sharedReport: TSharedEntity[] | number[];
	is_table: boolean | undefined;
	uuid?: string;

	constructor(report: TReport) {
		this.id = report.id;
		this.name = report.name;
		this.description = report.description;
		this.dataframe = report.dataframe;
		this.conditionalRules = report.conditionalRules;
		this.updated_at = report.updated_at;
		this.updater = report.updater;
		this.created_at = report.created_at;
		this.creator = report.creator;
		this.is_from_dataframe = report.is_from_dataframe;
		this.owner = report.owner;
		this.sharedReport = report.sharedReport;
		this.is_table = report.is_table;
		this.uuid = report.uuid;
	}

	public static New(report: TNewReport): Promise<TReport> {
		return axiosConfig.instance.post('app/report', report).then((res) => {
			return new Report(res.data as TReport);
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/report/${id}`).then(() => {
			return true;
		});
	}

	public static Patch(report: TNewReport): Promise<TReport> {
		return axiosConfig.instance.patch('app/report', report).then((res) => {
			return new Report(res.data as TReport);
		});
	}

	public static Get(id: number, expand: (keyof TReport)[] = []): Promise<TReport> {
		return axiosConfig.instance
			.get<TReport>(`app/report/${id}`, { params: { expand } })
			.then((res) => {
				return res.data;
			});
	}

	public static GetAll(expand: (keyof TReport)[] = [], is_table = false): Promise<TReport[]> {
		const isTable = is_table ? 'true' : 'false';
		return axiosConfig.instance
			.get<TReport[]>(`app/reports?isTable=${isTable}`, { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}
}
