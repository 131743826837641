import axiosConfig from '../axiosConfig';
import { TDataframe } from '../types';

export type TAlertDefinition = {
	id?: number;
	keyMeasureFact: number;
	name: string;
	headline: string;
	condition: string;
	value: number;
	color: string;
	period: number;
	monitorWindow: number;
	actions: string;
	effectiveBeginDate: number;
	effectiveEndDate: number;
	triggered: boolean;
	notifyByEmail: boolean;
	email_notification_date?: number;
	comparison_type?: string;
	comparisonKeyMeasureFact?: string;
	chart: number;
	dataframe: number | TDataframe;
	owner?: number;
	emailReminderDays?: number;
};

export type TNewAlertDefinition = Omit<TAlertDefinition, 'id'>;

export class AlertDefinition implements TAlertDefinition {
	id?: number;
	keyMeasureFact: number;
	name: string;
	headline: string;
	condition: string;
	value: number;
	color: string;
	period: number;
	monitorWindow: number;
	actions: string;
	effectiveBeginDate: number;
	effectiveEndDate: number;
	triggered: boolean;
	notifyByEmail: boolean;
	email_notification_date?: number;
	comparison_type?: string;
	comparisonKeyMeasureFact?: string;
	chart: number;
	dataframe: number | TDataframe;
	owner?: number;
	emailReminderDays?: number;

	constructor(alert: TAlertDefinition) {
		this.id = alert.id;
		this.keyMeasureFact = alert.keyMeasureFact;
		this.name = alert.name;
		this.headline = alert.headline;
		this.condition = alert.condition;
		this.value = alert.value;
		this.color = alert.color;
		this.period = alert.period;
		this.monitorWindow = alert.monitorWindow;
		this.actions = alert.actions;
		this.effectiveBeginDate = alert.effectiveBeginDate;
		this.effectiveEndDate = alert.effectiveEndDate;
		this.triggered = alert.triggered;
		this.notifyByEmail = alert.notifyByEmail;
		this.email_notification_date = alert.email_notification_date;
		this.comparison_type = alert.comparison_type;
		this.comparisonKeyMeasureFact = alert.comparisonKeyMeasureFact;
		this.chart = alert.chart;
		this.dataframe = alert.dataframe;
		this.owner = alert.owner;
		this.emailReminderDays = alert.emailReminderDays;
	}

	public static Default(): TNewAlertDefinition {
		return {
			actions: '',
			color: '',
			condition: '',
			effectiveBeginDate: 0,
			effectiveEndDate: 0,
			headline: '',
			keyMeasureFact: 0,
			monitorWindow: 0,
			notifyByEmail: false,
			period: 0,
			triggered: false,
			value: 0,
			chart: 0,
			dataframe: 0,
			name: '',
		};
	}

	public static GetAll(expand: (keyof TAlertDefinition)[] = []): Promise<TAlertDefinition[]> {
		return axiosConfig.instance
			.get('app/alert-definition', { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TAlertDefinition[]).map((entity) => {
					return entity;
				});
			});
	}

	public static Get(
		expand: (keyof TAlertDefinition)[] = [],
		id: number
	): Promise<TAlertDefinition> {
		return axiosConfig.instance
			.get(`app/alert-definition/${id}`, { params: { expand: expand } })
			.then((res) => {
				return res.data as TAlertDefinition;
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/alert-definition/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(entity: TAlertDefinition): Promise<TAlertDefinition> {
		return axiosConfig.instance.post('app/alert-definition', entity).then((res) => {
			return res.data as TAlertDefinition;
		});
	}

	public static Update(entity: TAlertDefinition): Promise<TAlertDefinition> {
		return axiosConfig.instance.patch('app/alert-definition', entity).then((res) => {
			return res.data as TAlertDefinition;
		});
	}
}
