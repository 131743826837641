import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { AlertVariant, Modal, ModalVariant } from '@patternfly/react-core';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { Dimension, DimensionTypeOptions, TDimension } from '../../api/analytics/Dimension';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import { DimensionAttribute, TDimensionAttribute } from '../../api/analytics/DimensionAttribute';
import SchnurForm, {
	EventType,
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { useMount } from 'react-use';
import SchemaUpdateButton from '../../components/button/SchemaUpdateButton';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';
import DimensionValueEditorModal from './DimensionValueEditorModal';

export default function DimensionsAttributes(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [data, setData] = useState<TDimensionAttribute[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [activeDimensionAttribute, setActiveDimensionAttribute] = useState<TDimensionAttribute>(
		DimensionAttribute.Default() as TDimensionAttribute
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(true);
	const [dimensions, setDimensions] = useState<TDimension[]>([]);
	const [isValueConfigModalOpen, setIsValueConfigModalOpen] = React.useState<boolean>(false);

	const selectedColumns: Column<DimensionAttribute>[] = [
		{
			title: 'Attribute Title',
			columnName: 'name',
			sortable: true,
		},
		{
			title: 'Data Type',
			columnName: 'type',
			customAccessor: (item) =>
				String(DimensionTypeOptions.find((option) => option.key === item.type)?.value),
			sortable: true,
		},
		{
			title: 'sequence',
			columnName: 'sequence',
			sortable: true,
		},
		{
			title: 'Dimension',
			columnName: 'dimension',
			sortable: false,
			customAccessor: (item) => {
				if (typeof item.dimension === 'object') {
					return item.dimension.name;
				}

				return item.dimension;
			},
		},
	];

	const actions: Action<TDimensionAttribute>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveDimensionAttribute(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditDimensionAttribute,
		},
		{
			name: (
				<>
					Configure Values <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveDimensionAttribute(item);
				setIsValueConfigModalOpen(true);
			},
			permission: Permission.EditDimensionAttribute,
		},
		{
			name: 'Delete',
			callback: (item) => {
				setTableLoading(true);
				DimensionAttribute.Delete(item.id)
					.then(() => {
						setTableLoading(false);
						setData((prev) => prev.filter((dimension) => dimension.id !== item.id));
						addToast('Dimension Attribute deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast(
							'An error occurred while trying to delete dimension attribute. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.DeleteDimensionAttribute,
		},
	];
	useMount(() => {
		Dimension.GetAll()
			.then((dimensions) => {
				setDimensions(dimensions);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load Dimensions. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});
	});

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Dimension Attributes"
					pageDescription="Manage your Dimensions Attributes."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});

		DimensionAttribute.GetAll(['dimension'])
			.then((dimensionAttributes) => {
				setData(dimensionAttributes);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load dimensions attributes. Please try again later.',
					AlertVariant.danger
				);
			});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: TDimensionAttribute) => {
		const index = data.findIndex((dimensionAttribute) => dimensionAttribute.id === value.id);

		if (index >= 0) {
			setData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const addButton = (
		<PermissionButton
			data-testid={'dimensionAttribute-add-button'}
			variant={'primary'}
			permission={Permission.CreateDimensionAttribute}
			onClick={() => {
				setActiveDimensionAttribute(DimensionAttribute.Default() as TDimensionAttribute);
				setIsModalOpen(true);
			}}
		>
			New Dimension Attribute
		</PermissionButton>
	);

	const formProperties: Field<TDimensionAttribute>[] = [
		{
			title: 'Alias',
			columnName: 'code',
			uiSchema: {
				type: UIType.TEXT,
				placeholder: 'dimension_attribute_alias',
				helpText: 'The alias of the dimension attribute to use in SQL statements.',
			},
			required: true,
			validate: (eventType, value) => {
				if (eventType !== EventType.BLUR && eventType !== EventType.CHANGE) {
					return true;
				}

				if (value.includes(' ')) {
					return 'An alias must not contain a space!';
				}

				return true;
			},
		},
		{
			title: 'Title',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'The name of the dimension attribute.',
			},
			required: true,
		},
		{
			title: 'Dimension',
			columnName: 'dimension',
			uiSchema: {
				type: UIType.SELECT,
				options: dimensions.map((dimension) => {
					return {
						key: dimension.id,
						value: dimension.name,
						description: dimension.description ?? undefined,
					};
				}),
				initialSelection:
					typeof activeDimensionAttribute.dimension === 'object'
						? activeDimensionAttribute.dimension.name
						: null,
				onSelect: (selected: ISelectOption) => {
					return dimensions.find((dimension) => dimension.id === selected.key);
				},
			},
			required: true,
		},
		{
			title: 'Type',
			columnName: 'type',
			uiSchema: {
				type: UIType.SELECT,
				options: DimensionTypeOptions,
				onSelect: (selected: ISelectOption) => {
					return selected.key;
				},
				initialSelection:
					activeDimensionAttribute.type !== '' ? activeDimensionAttribute.type : null,
			},
			required: true,
		},
		{
			title: 'Sequence',
			columnName: 'sequence',
			uiSchema: {
				type: UIType.NUMBER,
			},
			required: true,
		},
		{
			title: 'Display for associated dimension values',
			columnName: 'display_attribute',
			uiSchema: {
				type: UIType.BOOLEAN,
			},
		},
		{
			title: 'Disabled',
			columnName: 'disabled',
			uiSchema: {
				type: UIType.BOOLEAN,
			},
		},
		{
			title: 'Effective Dated',
			columnName: 'effective_dated',
			uiSchema: {
				type: UIType.BOOLEAN,
			},
		},
	];

	const dimensionTable = (
		<ZiTable<DimensionAttribute>
			ariaLabel={'Dimension Attributes table'}
			columns={selectedColumns}
			data={data}
			caption="Dimensions Attributes"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Dimension Attribute Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TDimensionAttribute>
					title={'Dimension Attribute'}
					fields={formProperties}
					initialSubject={activeDimensionAttribute}
					isLoading={isFormLoading}
					onSubmit={(dimensionAttribute) => {
						setIsFormLoading(true);

						if ('id' in dimensionAttribute) {
							DimensionAttribute.Update(dimensionAttribute, ['dimension'])
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									setActiveDimensionAttribute(dimensionAttribute);
									addToast(
										'An error occurred while trying to save dimension attribute. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							DimensionAttribute.New(dimensionAttribute, ['dimension'])
								.then((newDimension) => {
									handleSuccess(newDimension);
								})
								.catch(() => {
									setActiveDimensionAttribute(dimensionAttribute);
									addToast(
										'An error occurred while trying to save dimension attribute. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={dimensionTable}
					layoutActions={[addButton, <SchemaUpdateButton />]}
				/>
			</React.Fragment>
			{isValueConfigModalOpen && (
				<React.Fragment>
					<DimensionValueEditorModal
						id={activeDimensionAttribute.id}
						name={activeDimensionAttribute.name}
						isDimension={false}
						handleClose={() => {
							setIsValueConfigModalOpen(false);
						}}
					></DimensionValueEditorModal>
				</React.Fragment>
			)}
		</React.Fragment>
	);
}
