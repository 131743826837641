import React, { Dispatch, SetStateAction, useState } from 'react';
import { useMount } from 'react-use';
import { DropdownGroup, DropdownItem, FormGroup, TreeViewDataItem } from '@patternfly/react-core';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import Loader from '../../util/Loader';
import SearchableTreeViewSelect, {
	dropdownToTreeViewData,
} from '../../form/Select/SearchableTreeViewSelect';
import { Dashboard, TDashboard } from '../../../api/dashbboards/Dashboards';
import { GenericStringKeyValueResponse } from '../../../api/types';
import { TFolder } from '../../../api/types/folder';
import _ from 'lodash';
import { buildFolderDisplayForDropdown } from '../../../helpers/dropdown-folder.helper';

export type CopyDashboardOptionsViewProps = {
	selectedDashboard?: TDashboard;
	onSelectDashboard: Dispatch<SetStateAction<TDashboard>>;
};

const CopyDashboardOptionsView = (props: CopyDashboardOptionsViewProps) => {
	const [filteredItems, setFilteredItems] = useState<TreeViewDataItem[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedDashboardName, setSelectedDashboardName] = useState<string>();
	const [dashboards, setDashboards] = useState<TDashboard[]>([]);
	const [groupedDropdownItems, setGroupedDropdownItems] = useState<JSX.Element[]>([]);

	useMount(() => {
		setIsLoading(true);

		void Dashboard.GetAll(['folder']).then((response) => {
			setDashboards(response);

			const groupedDashboards = _.groupBy(response, 'folder.id');
			const dashboardFolderKeys = Object.keys(groupedDashboards);
			const dropdownHtml: JSX.Element[] = [];
			const dashboardFolders = response.map((x) => x.folder as TFolder);
			const kvpDashboards: GenericStringKeyValueResponse<string>[] =
				buildFolderDisplayForDropdown(dashboardFolderKeys, dashboardFolders);

			kvpDashboards.forEach((folderKey) => {
				let itemsByKey: TDashboard[] = [];
				const folder = dashboardFolders.find((x) => x?.id == +folderKey.key);
				let folderName = folder?.name;

				//condition for when chart does not belong to a folder
				if (!folderName) {
					folderName = 'Unassigned';
					itemsByKey = response.filter((x) => (x?.folder as TFolder) === null);
				} else {
					itemsByKey = response.filter(
						(x) => (x?.folder as TFolder)?.id === +folderKey.key
					);
				}

				dropdownHtml.push(
					<DropdownGroup
						label={`Folder: ${folderKey.value != '' ? folderKey.value : 'Unassigned'}`}
						key={folderKey.key}
					>
						{itemsByKey.map((item) => (
							<DropdownItem
								id={item.id?.toString()}
								key={item.name}
							>
								{item.name}
							</DropdownItem>
						))}
					</DropdownGroup>
				);
			});
			setGroupedDropdownItems(dropdownHtml);

			const parsedItems: { id: number; name: string; display_name?: string | null }[] = [];

			response.map((chart) => {
				const parsedItem: { id: number; name: string; display_name?: string | null } = {
					id: chart.id ?? 0,
					name: chart.name,
				};

				parsedItems.push(parsedItem);
			});

			setFilteredItems(parsedItems.map((_) => BuildTreeViewItem(_, [])));
			setIsLoading(false);
		});
	});

	const selectDashboard = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	): void => {
		const dashboardId = item.id?.toString() ?? '';
		const chosenDashboard = dashboards.find((x) => x.id == +dashboardId);

		if (chosenDashboard) {
			props.onSelectDashboard(chosenDashboard);
		}

		setSelectedDashboardName(item.name?.toString());
	};

	const emptyTree = [BuildTreeViewItem({ id: -1, name: 'No Dashboards were found' }, [])];
	const treeViewData = dropdownToTreeViewData(groupedDropdownItems);

	return isLoading ? (
		<Loader />
	) : (
		<div data-testid="copy-view">
			<FormGroup>
				<SearchableTreeViewSelect
					className="no-innerscroll"
					data-testid="dashboard-tree"
					data={filteredItems && filteredItems.length > 0 ? treeViewData : emptyTree}
					placeHolderText={selectedDashboardName ?? 'Search for a Dashboard'}
					onSelect={selectDashboard}
					treeItemsExpanded={true}
					isTopLvlSearchOnly={false}
				/>
			</FormGroup>
		</div>
	);
};

export default CopyDashboardOptionsView;
