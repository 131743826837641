import React, { Dispatch, SetStateAction, useState } from 'react';
import { useMount } from 'react-use';
import { DropdownGroup, DropdownItem, FormGroup, TreeViewDataItem } from '@patternfly/react-core';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { Chart, TChart } from '../../../api/charts/Chart';
import Loader from '../../util/Loader';
import SearchableTreeViewSelect, {
	dropdownToTreeViewData,
} from '../../form/Select/SearchableTreeViewSelect';
import _ from 'lodash';
import { TFolder } from '../../../api/types/folder';
import { buildFolderDisplayForDropdown } from '../../../helpers/dropdown-folder.helper';

export type CopyChartOptionsViewProps = {
	selectedChart?: TChart;
	onSelectChart: Dispatch<SetStateAction<TChart | undefined>>;
};

const CopyChartOptionsView = (props: CopyChartOptionsViewProps) => {
	const [filteredItems, setFilteredItems] = useState<TreeViewDataItem[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedChartName, setSelectedChartName] = useState<string>();
	const [charts, setCharts] = useState<TChart[]>([]);
	const [groupedDropdownItems, setGroupedDropdownItems] = useState<JSX.Element[]>([]);

	useMount(() => {
		setIsLoading(true);

		void Chart.GetAll(['folder']).then((response) => {
			setCharts(response);

			const groupedCharts = _.groupBy(response, 'folder.id');
			const chartFolderKeys = Object.keys(groupedCharts);
			const dropdownHtml: JSX.Element[] = [];
			const chartFolders = response.map((x) => x.folder as TFolder);
			const kvpCharts = buildFolderDisplayForDropdown(chartFolderKeys, chartFolders);

			kvpCharts.forEach((folderKey) => {
				let itemsByKey: TChart[] = [];
				const folder = chartFolders.find((x) => x?.id == +folderKey.key);
				let folderName = folder?.name;

				//condition for when chart does not belong to a folder
				if (!folderName) {
					folderName = 'Unassigned';
					itemsByKey = response.filter((x) => !x.folder);
				} else {
					itemsByKey = response.filter(
						(x) => (x?.folder as TFolder)?.id === +folderKey.key
					);
				}

				dropdownHtml.push(
					<DropdownGroup
						label={`Folder: ${folderKey.value != '' ? folderKey.value : 'Unassigned'}`}
						key={folderKey.key !== 'undefined' ? folderKey.key : '0'}
					>
						{itemsByKey.map((item) => (
							<DropdownItem
								id={item.id?.toString() ?? '0'}
								key={item.name}
							>
								{item.name}
							</DropdownItem>
						))}
					</DropdownGroup>
				);
			});
			setGroupedDropdownItems(dropdownHtml);

			const parsedItems: { id: number; name: string; display_name?: string | null }[] = [];

			response.map((chart) => {
				const parsedItem: { id: number; name: string; display_name?: string | null } = {
					id: chart.id ?? 0,
					name: chart.name,
				};

				parsedItems.push(parsedItem);
			});

			setFilteredItems(parsedItems.map((_) => BuildTreeViewItem(_, [])));
			setIsLoading(false);
		});
	});

	const selectChart = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	): void => {
		const chartId = item.id?.toString() ?? '';
		const chosenChart = charts.find((x) => x.id == +chartId);

		props.onSelectChart(chosenChart);
		setSelectedChartName(item.name?.toString());
	};

	const emptyTree = [BuildTreeViewItem({ id: -1, name: 'No Charts were found' }, [])];
	const treeViewData = dropdownToTreeViewData(groupedDropdownItems);

	return isLoading ? (
		<Loader />
	) : (
		<div data-testid="copy-view">
			<FormGroup>
				<SearchableTreeViewSelect
					className="no-innerscroll"
					data-testid="chart-tree"
					data={filteredItems && filteredItems.length > 0 ? treeViewData : emptyTree}
					placeHolderText={selectedChartName ?? 'Search for a Chart'}
					onSelect={selectChart}
					treeItemsExpanded={true}
					isTopLvlSearchOnly={false}
				/>
			</FormGroup>
		</div>
	);
};

export default CopyChartOptionsView;
