import React from 'react';
import { FormGroup, TreeViewDataItem } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import TreeViewSelect from '../../components/form/Select/TreeViewSelect';
import { BuildTreeViewItem } from '../tree-view.helper';
import { TFolder } from '../../api/foundational-elements/Folder';

type props = {
	folderId?: number;
	folders: TFolder[];
	onFolderSelect: (e: any, item: TreeViewDataItem) => void;
	isDisabled?: boolean;
};

function SelectFolderDropdown(props: props) {
	const emptyTree = [BuildTreeViewItem({ id: -1, name: 'No folders were found' }, [])];
	const selectedFolderName = props.folderId
		? props.folders.find((f) => f.id === props.folderId)?.name
		: 'Select a Folder';

	return (
		<FormGroup
			label={<FontAwesomeIcon icon={faFolder} />}
			type="text"
			fieldId="folder"
			className="folder-container"
		>
			<TreeViewSelect
				onSelect={(e: any, item: TreeViewDataItem) => {
					if (item && item.id && item.id != '-1') {
						props.onFolderSelect(e, item);
					}
				}}
				data={
					props.folders && props.folders.length > 0
						? props.folders.map((_: TFolder) => BuildTreeViewItem(_, _.items))
						: emptyTree
				}
				placeHolderText={selectedFolderName}
				isDisabled={props.isDisabled}
			/>
		</FormGroup>
	);
}

export default SelectFolderDropdown;
