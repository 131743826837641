import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { AlertVariant, Modal, ModalVariant } from '@patternfly/react-core';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import SchnurForm, { EventType, Field, UIType } from '../../components/form/SchnurForm/SchnurForm';
import { Permission } from '../../enums/permission.enum';
import { TZoneSetting, ZoneSetting } from '../../api/zone/ZoneSetting';
import FilterIcon from '@fortawesome/fontawesome-pro/svgs/regular/filter.svg';

export default function ZoneSettings(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [data, setData] = useState<ZoneSetting[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [activeSetting, setActiveSetting] = useState<TZoneSetting>(
		ZoneSetting.DefaultNewZoneSetting() as TZoneSetting
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

	const selectedColumns: Column<TZoneSetting>[] = [
		{
			title: 'Name',
			columnName: 'name',
			sortable: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			sortable: true,
		},
		{
			title: 'Enabled',
			columnName: 'enabled',
			sortable: true,
			customAccessor: (item) => String(item.enabled ? 'Yes' : 'No'),
		},
	];

	const formProperties: Field<TZoneSetting>[] = [
		{
			title: 'Name',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
			},
			disabled: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			uiSchema: {
				type: UIType.TEXTAREA,
			},
			maxLength: 255,
		},
		{
			title: 'Value',
			columnName: 'value',
			uiSchema: {
				type: UIType.TEXTAREA,
			},
			required: true,
			validate: (eventType, value) => {
				if (eventType !== EventType.BLUR && eventType !== EventType.CHANGE) {
					return true;
				}

				return true;
			},
		},
		{
			title: 'Enabled',
			columnName: 'enabled',
			uiSchema: {
				type: UIType.BOOLEAN,
			},
		},
	];

	const actions: Action<ZoneSetting>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveSetting(item);
				setIsModalOpen(true);
			},
			permission: Permission.CanEditZoneSettings,
		},
	];

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Zone Settings"
					pageDescription="Manage settings for all zones."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});

		ZoneSetting.GetAll()
			.then((setting) => {
				setData(setting);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load Zone Settings. Please try again later.',
					AlertVariant.danger
				);
			});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: ZoneSetting) => {
		const index = data.findIndex((setting) => setting.id === value.id);

		if (index >= 0) {
			setData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const zoneSettingsTable = (
		<ZiTable<ZoneSetting>
			ariaLabel={'Zone Settings table'}
			columns={selectedColumns}
			data={data}
			caption="Zone Settings"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Zone Setting Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TZoneSetting>
					title={'Zone Setting Management'}
					fields={formProperties}
					initialSubject={activeSetting}
					isLoading={isFormLoading}
					onSubmit={(setting) => {
						setIsFormLoading(true);

						ZoneSetting.Update(setting)
							.then((updated) => {
								handleSuccess(updated);
							})
							.catch(() => {
								addToast(
									'An error occurred while trying to save Zone Settings. Please try again later.',
									AlertVariant.danger
								);
							})
							.finally(() => {
								setIsFormLoading(false);
							});
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={zoneSettingsTable}
					icon={FilterIcon}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
