import React, { createContext, useContext, useState, ReactNode } from 'react';
import { DashboardFilterStore } from '../../types/dashboards/dashboard-filter-store';

interface CommonStoreContext {
	sharedDashboardFilters: DashboardFilterStore[];
	setSharedDashboardFilters: (filters: DashboardFilterStore[]) => void;
}

const CommonStoreContext = createContext<CommonStoreContext | null>(null);

export const CommonStoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [sharedDashboardFilters, setSharedDashboardFilters] = useState<DashboardFilterStore[]>(
		[]
	);

	return (
		<CommonStoreContext.Provider value={{ sharedDashboardFilters, setSharedDashboardFilters }}>
			{children}
		</CommonStoreContext.Provider>
	);
};

export const useCommonStoreContext = () => {
	const context = useContext(CommonStoreContext);
	if (!context)
		throw new Error('useCommonStoreContext must be used within a CommonStoreProvider');
	return context;
};
