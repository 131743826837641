import {
	ActionsColumn,
	Caption,
	IAction,
	TableComposable,
	Tbody,
	Td,
	Th,
	Thead,
	ThProps,
	Tr,
} from '@patternfly/react-table';
import React, { useEffect, useState } from 'react';
import { cleanupPropertyName } from '../../utilities';
import { Flex, FlexItem, Pagination, PaginationVariant, Spinner } from '@patternfly/react-core';
import { Permission } from '../../enums/permission.enum';
import { useIsGranted } from '../user/ApplicationProvider';
import { FolderTypesEnum } from '../../enums/folder-types-enum';
import { useNavigate, useLocation } from 'react-router-dom';

export type Pick<T, K extends keyof T> = {
	[P in K]: T[P];
};

export type Column<T> = {
	columnName: keyof T;
	sortable?: boolean;
	title: string;
	customAccessor?: (row: T) => string | number | boolean | JSX.Element;
};

type InternalColumn = {
	title: string;
	sortable: boolean;
};

export type ActionCallback<T> = (clickedObject: T) => void;
export type InlineCallback<T> = (clickedObject: T) => boolean;

export type Action<T> = {
	name: string | React.ReactNode;
	callback: ActionCallback<T>;
	permission?: Permission | Permission[];
	hideOption?: InlineCallback<T>;
};

type Props<T> = {
	columns: (keyof T)[] | Column<T>[];
	data: T[];
	caption?: string;
	ariaLabel: string;
	filter?: string;
	actions?: Action<T>[];
	defaultPageSize?: number;

	loading?: boolean;
};
export default function ZiTable<T>(props: Props<T>) {
	const [normalizedColumns, setNormalizedColumns] = useState<InternalColumn[]>([]);
	const [page, setPage] = React.useState(1);
	const [perPage, setPerPage] = React.useState(props.defaultPageSize ?? 10);
	const [activeSortIndex, setActiveSortIndex] = React.useState<number | undefined>(undefined);
	const [activeSortDirection, setActiveSortDirection] = React.useState<
		'asc' | 'desc' | undefined
	>(undefined);
	const [normalizedActions, setNormalizedActions] = useState<Action<T>[]>([]);
	const isGranted = useIsGranted();
	const navigate = useNavigate();
	const pathname = useLocation().pathname;
	const mapIndexToColumn = new Map();

	useEffect(() => {
		setNormalizedColumns(
			props.columns.map((column) => {
				const columnTitle =
					typeof column === 'string' ? column : (column as Column<T>).title;
				const columnSortable =
					typeof column === 'string' ? false : (column as Column<T>).sortable;

				return {
					title: cleanupPropertyName(String(columnTitle)),
					sortable: columnSortable,
				} as InternalColumn;
			})
		);
	}, [props.columns]);

	useEffect(() => {
		if (!props.actions) return;

		setNormalizedActions(
			props.actions.filter((action) => {
				if (action.permission) {
					return isGranted(action.permission);
				}

				return true;
			})
		);
	}, [props.actions]);

	props.columns.forEach((column, columnIndex) => {
		const columnName = typeof column === 'string' ? column : (column as Column<T>).columnName;
		mapIndexToColumn.set(columnIndex, columnName);
	});

	let sortedData = props.data;

	if (activeSortIndex !== undefined) {
		sortedData = props.data.sort((a, b) => {
			const aValue = a[mapIndexToColumn.get(activeSortIndex) as keyof T];
			const bValue = b[mapIndexToColumn.get(activeSortIndex) as keyof T];

			if (typeof aValue === 'number') {
				if (activeSortDirection === 'asc') {
					return aValue - (bValue as number);
				}

				return (bValue as number) - aValue;
			} else if (typeof aValue === 'string') {
				if (activeSortDirection === 'asc') {
					return (aValue as string).localeCompare(bValue as string);
				}

				return (bValue as string).localeCompare(aValue as string);
			} else if (typeof aValue === 'boolean') {
				if (activeSortDirection === 'asc') {
					return aValue ? 1 : -1;
				}

				return aValue ? -1 : 1;
			}
			return 0;
		});
	}

	if (props.filter !== undefined && props.filter !== '') {
		const finalFilter = props.filter.toLowerCase();
		sortedData = sortedData.filter((row) => {
			for (const column of props.columns) {
				const columnName =
					typeof column === 'string' ? column : (column as Column<T>).columnName;
				const data = row[columnName];

				if (String(data).toLowerCase().includes(finalFilter)) {
					return true;
				}
			}

			return false;
		});
	}

	const getSortParams = (columnIndex: number, _column: string): ThProps['sort'] => ({
		sortBy: {
			index: activeSortIndex,
			direction: activeSortDirection,
			defaultDirection: 'asc',
		},
		onSort: (_event, index, direction) => {
			setActiveSortIndex(index);
			setActiveSortDirection(direction);
		},
		columnIndex,
	});

	const defaultActions = (row: T): IAction[] => {
		const actions = [];
		for (const action of normalizedActions || []) {
			const hideOption = action.hideOption && action.hideOption(row);
			if (hideOption && hideOption == true) {
				continue;
			}
			actions.push({
				title: action.name,
				onClick: () => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					action.callback(row);
				},
			});
		}

		return actions;
	};

	const onSetPage = (
		_event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
		newPage: number
	) => {
		setPage(newPage);
	};

	const onPerPageSelect = (
		_event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
		newPerPage: number,
		newPage: number
	) => {
		setPerPage(newPerPage);
		setPage(newPage);
	};

	const startIndex = (page - 1) * perPage;
	const endIndex = startIndex + perPage;
	const currentPagedData = sortedData.slice(startIndex, endIndex);

	function getPropertyIfExists<T>(obj: T, propertyName: string): any | undefined {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, no-prototype-builtins
		if ((obj as any).hasOwnProperty(propertyName)) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			return (obj as any)[propertyName];
		} else {
			return undefined;
		}
	}

	function navigateToDataframeFolder<T>(row: T) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const id = getPropertyIfExists(row, 'id');
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const name = getPropertyIfExists(row, 'name');

		if (id !== undefined && name !== undefined) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			localStorage.setItem('selectedDataframeFolderId', id);
			setTimeout(() => {
				if (pathname.includes('/folder/')) {
					localStorage.setItem('currentNestedFolderPath', window.location.pathname);
				}
			}, 500);

			if (pathname.includes('/folder/')) {
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				const navUrl = pathname + `/folder/${name}/${id}`;
				localStorage.setItem('prevDataframePage', navUrl);

				navigate(navUrl);
			} else {
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				const navUrl = `/analyze/${FolderTypesEnum.DataFrame}/folder/${name}/${id}`;

				localStorage.setItem('prevDataframePage', navUrl);
				navigate(navUrl);
			}
		}
	}

	return (
		<React.Fragment>
			{props.loading === true ? (
				<Flex justifyContent={{ default: 'justifyContentCenter' }}>
					<FlexItem>
						<Spinner
							isSVG
							diameter={'40px'}
						/>
					</FlexItem>
				</Flex>
			) : (
				<React.Fragment>
					<TableComposable
						className="pf-m-sticky-header"
						aria-label={props.ariaLabel}
					>
						{props.caption && <Caption>{props.caption}</Caption>}
						<Thead>
							<Tr>
								{normalizedColumns.map((column, index) => (
									<Th
										className={
											column.title.toLowerCase().includes('date')
												? 'th-date'
												: ''
										}
										key={index}
										sort={
											column.sortable
												? getSortParams(index, column.title)
												: undefined
										}
									>
										{String(column.title)}
									</Th>
								))}
								{normalizedActions.length !== 0 ? (
									<Td key="actions">Actions</Td>
								) : null}
							</Tr>
						</Thead>
						<Tbody>
							{currentPagedData.map((row, rowIndex) => {
								const actionsArray = defaultActions(row);
								return (
									<Tr
										key={`table-row-${rowIndex}`}
										className="zi-table-row"
									>
										{props.columns.map((column, columnIndex) => {
											const columnName =
												typeof column === 'string'
													? column
													: (column as Column<T>).columnName;

											let data:
												| string
												| number
												| boolean
												| JSX.Element
												| null = row[columnName] as
												| string
												| number
												| boolean
												| null;

											if (
												typeof column === 'object' &&
												'customAccessor' in column &&
												column.customAccessor !== undefined
											) {
												data = column.customAccessor(row);
											}

											let outData: JSX.Element | string = '';

											if (typeof data === 'boolean') {
												outData = data ? 'Yes' : 'No';
											} else if (typeof data === 'object') {
												outData = data as JSX.Element;
											} else {
												outData = String(data);
											}

											if (
												outData === undefined ||
												outData === null ||
												outData === 'null' ||
												outData === 'undefined'
											) {
												outData = '';
											}

											return (
												<Td
													key={`${rowIndex}-${columnIndex}`}
													dataLabel={String(column)}
													onClick={(e) => {
														// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
														const rowType = getPropertyIfExists(
															row,
															'type'
														);

														//For dataframes when the row type is folders, do not use the normalizedActions callback
														//but instead navigate to the folder.
														if (
															rowType !== undefined &&
															rowType == 'folders'
														) {
															navigateToDataframeFolder(row);
														} else if (normalizedActions.length > 0) {
															const editAction =
																normalizedActions.find((x) => {
																	if (
																		Array.isArray(x.permission)
																	) {
																		return x.permission.some(
																			(perm) =>
																				perm
																					.toString()
																					.toLowerCase()
																					.includes(
																						'edit'
																					)
																		);
																	} else if (x.permission) {
																		return x.permission
																			.toString()
																			.toLowerCase()
																			.includes('edit');
																	}
																	return false;
																});

															if (!editAction) {
																return;
															}
															editAction.callback(row);
														}
													}}
												>
													{outData === null || outData === undefined
														? 'a'
														: outData}
												</Td>
											);
										})}
										{normalizedActions.length !== 0 ? (
											<Td>
												<ActionsColumn items={defaultActions(row)} />
											</Td>
										) : null}
									</Tr>
								);
							})}
						</Tbody>
					</TableComposable>
					<Pagination
						perPageComponent="button"
						itemCount={sortedData.length}
						widgetId="table-pagination-bottom"
						perPage={perPage}
						page={page}
						variant={PaginationVariant.bottom}
						onSetPage={onSetPage}
						onPerPageSelect={onPerPageSelect}
					/>
				</React.Fragment>
			)}
		</React.Fragment>
	);
}
