import { Modal, Text } from '@patternfly/react-core';
import { useNavigate } from 'react-router-dom';
import { TUser, User } from '../../api/security/User';
import { useState } from 'react';
import ChangePasswordModal from './ChangePasswordModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import AboutModal from './AboutModal';

type Props = {
	isOpen: boolean;
	user: TUser | undefined;
	onClose: () => void;
};

function UserProfileModal(props: Props) {
	const navigate = useNavigate();
	const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<boolean>(false);
	const [isAboutModalOpen, setIsAboutModalOpen] = useState<boolean>(false);

	const onToggle = () => {
		props.onClose();
		setIsChangePasswordModalOpen(true);
	};

	const closeChangePasswordModal = () => {
		setIsChangePasswordModalOpen(false);
	};

	const signout = () => {
		User.Logout()
			.then((url: string) => {
				if (url) {
					window.location.href = url;
				} else {
					navigate('/login');
				}
			})
			.catch(() => {
				navigate('/login');
			});
	};

	const closeAboutModal = () => {
		setIsAboutModalOpen(false);
	};

	return (
		<>
			<Modal
				title=" "
				variant="small"
				width={350}
				isOpen={props.isOpen}
				onClose={props.onClose}
			>
				<div className="text-center">
					<Text>
						<FontAwesomeIcon
							icon={faUser}
							fontSize={'80px'}
						/>
					</Text>
					<br />
					<Text component="h2">{props.user ? props.user.name : ''}</Text>
				</div>
				<br />
				<hr />
				<br />
				<div>
					<Text
						component="a"
						style={{ color: 'black' }}
					>
						My Preferences
					</Text>
					<br />
					<Text
						component="a"
						onClick={() => onToggle()}
						style={{ color: 'black' }}
					>
						Change password
					</Text>
				</div>
				<br />
				<hr />
				<br />
				<div>
					<Text
						component="a"
						style={{ color: 'black' }}
						onClick={signout}
						data-testid="user-logout-button"
					>
						Sign Out
					</Text>
					<br />
					<Text
						component="a"
						onClick={() => {
							setIsAboutModalOpen(true);
							props.onClose();
						}}
						style={{ color: 'black' }}
					>
						About
					</Text>
				</div>
				<br />
				<div>
					<Text component="p">
						(c) {new Date().getFullYear()} ZeroedIn Technologies, LLC.
					</Text>
					{/* To be add back once the versioning ticket is started */}
					{/* <Text component="p">Version X.X.X Release Y</Text> */}
				</div>
			</Modal>
			<ChangePasswordModal
				isOpen={isChangePasswordModalOpen}
				onClose={closeChangePasswordModal}
				user={props.user}
			/>
			<AboutModal
				isOpen={isAboutModalOpen}
				onClose={closeAboutModal}
			/>
		</>
	);
}

export default UserProfileModal;
