import React, { Dispatch, SetStateAction, useState } from 'react';
import { AlertVariant, FormGroup, TreeViewDataItem } from '@patternfly/react-core';
import { useMount } from 'react-use';
import { TDataframe } from '../../../../api/types';
import { Dataframe } from '../../../../api/dataframes/Dataframes';
import Loader from '../../../util/Loader';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import SearchableTreeViewSelect from '../../../form/Select/SearchableTreeViewSelect';
import { BuildTreeViewItem } from '../../../../helpers/tree-view.helper';
import { TKeyMeasureFact } from '../../../../api/analytics/KeyMeasureFact';
import { useApplication } from '../../../user/ApplicationProvider';
import { TKeyMeasure } from '../../../../api/analytics/KeyMeasure';
import './KeyMeasureView.scss';
import _ from 'lodash';

export type DataframeViewProps = {
	selectedFrame?: TDataframe;
	onSelectFrame: Dispatch<SetStateAction<TDataframe | undefined>>;
	onSelectMeasure: Dispatch<SetStateAction<TKeyMeasure | undefined>>;
	onSelectFact: Dispatch<SetStateAction<TKeyMeasureFact | undefined>>;
	allowFactSelection: boolean;
};

const DataFrameView = (props: DataframeViewProps) => {
	const { measures } = useApplication();
	const [dataframes, setDataframes] = useState<TDataframe[]>([]);
	const [_isDataframeOpen, setIsDataframeOpen] = useState(false);
	const [availableMeasures, setAvailableMeasures] = useState<TKeyMeasure[]>([]);

	const [isLoading, setIsLoading] = useState(false);
	const { addToast } = useToast();

	useMount(() => {
		setIsLoading(true);
		props.onSelectMeasure(undefined);
		props.onSelectFact(undefined);
		props.onSelectFrame(undefined);

		Dataframe.GetAll(['datasets'])
			.then((res) => {
				setDataframes(res);
			})
			.catch((e: Error) =>
				addToast(`Error fetching all dataframes, error: ${e.message}`, AlertVariant.danger)
			)
			.finally(() => {
				setIsLoading(false);
			});
	});

	const onSelectFrame = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	) => {
		if (item.id) {
			const frame = dataframes.find((_) => _.id === +(item.id ?? '0'));
			props.onSelectFrame(frame);

			if (frame) {
				setupMeasureSelection(frame);
			}
		} else {
			props.onSelectFrame(undefined);
		}

		if (!props.allowFactSelection) {
			setIsDataframeOpen(false);
		}

		onFocusDataframes();
	};

	const onFocusDataframes = () => {
		const element = document.getElementById('dataframe-selector');
		element?.focus();
	};

	const setupMeasureSelection = (dataframe: TDataframe) => {
		const measuresToUse: TKeyMeasure[] = [];
		const factIds = dataframe.datasets.map((fact) => fact.keyMeasureFact);

		measures.forEach((measure: TKeyMeasure) => {
			const clonedMeasure = _.cloneDeep(measure);
			clonedMeasure.keyMeasureFacts = clonedMeasure.keyMeasureFacts.filter(
				(fact: TKeyMeasureFact) => {
					return factIds && factIds.includes(fact.id);
				}
			);
			if (clonedMeasure.keyMeasureFacts.length > 0) {
				measuresToUse.push(clonedMeasure);
			}
		});

		setAvailableMeasures(measuresToUse);
	};

	const onSelectKeyMeasure = (
		event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	) => {
		if (item.id && item.id?.includes('__') && item.id !== '-1') {
			const [measureId, factId] = item.id.split('__');
			const measure = measures.find((_) => _.id === +measureId);
			props.onSelectMeasure(measure);
			props.onSelectFact(measure?.keyMeasureFacts.find((_) => _.id === +factId));
		} else if (item.id !== undefined && item.id !== '-1') {
			const id = parseInt(item.id) ?? 0;
			const measure = measures.find((_) => _.id === id);
			props.onSelectMeasure(measure);
			props.onSelectFact(measure?.keyMeasureFacts[0]);
		} else if (item.id && item.id === '-1') {
			props.onSelectFact(undefined);
		}
	};

	const emptyTree = [BuildTreeViewItem({ id: -1, name: 'No Dataframes were found' }, [])];

	return isLoading ? (
		<Loader />
	) : (
		<div
			className="options-container"
			data-testid="dataframe-view"
		>
			<div className="form-group-col">
				<FormGroup
					label="Dataframe"
					isRequired
					fieldId="dataframe"
				>
					<SearchableTreeViewSelect
						className="selectDataframe"
						data-testid="dataframe-tree"
						data={
							dataframes.length > 0
								? dataframes.map((km) => BuildTreeViewItem(km, []))
								: emptyTree
						}
						placeHolderText={props.selectedFrame?.name ?? 'Select a Dataframe'}
						onSelect={onSelectFrame}
						treeItemsExpanded={true}
						isTopLvlSearchOnly={true}
					/>
				</FormGroup>
			</div>
			{props.allowFactSelection && props.selectedFrame && (
				<div className="form-group-col">
					<FormGroup
						label="Key Measure"
						isRequired
					>
						<SearchableTreeViewSelect
							data-testid="key-measure-tree"
							className="key-measure-tree no-innerscroll"
							data={availableMeasures.map((km) =>
								BuildTreeViewItem(km, km.keyMeasureFacts)
							)}
							placeHolderText={'Select a Fact'}
							onSelect={onSelectKeyMeasure}
							treeItemsExpanded={true}
						/>
					</FormGroup>
				</div>
			)}
		</div>
	);
};

export default DataFrameView;
