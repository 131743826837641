import React, { Dispatch, SetStateAction } from 'react';
import { Button, Flex, Grid, GridItem } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import './DefaultPresentOptionsView.scss';
import { PresentCreationOptions } from './ZiPresentOptionsModal';

export type DefaultPresentOptionsViewProps = {
	onViewChange: Dispatch<SetStateAction<PresentCreationOptions>>;
	handleCreate: () => void;
};

const DefaultPresentOptionsView = (props: DefaultPresentOptionsViewProps) => {
	const { onViewChange, handleCreate } = props;

	return (
		<div
			className="component-container"
			data-testid="default-view"
		>
			<Grid className="present-options-list">
				<GridItem span={6}>
					<Flex justifyContent={{ default: 'justifyContentCenter' }}>
						<Button
							className="present-option"
							onClick={() => handleCreate()}
							variant="link"
							data-testid="select-blank"
						>
							<span className="present-option-icon"></span>
							Blank
						</Button>
					</Flex>
				</GridItem>
				{/* <GridItem span={6}>
					<Flex justifyContent={{ default: 'justifyContentCenter' }}>
						<Button
							className="present-option"
							onClick={() => onViewChange('grid')}
							variant="link"
							data-testid="select-frame"
						>
							<span className="present-option-icon">
								<FontAwesomeIcon icon={faObjectsColumn} />
							</span>
							Grid Template
						</Button>
					</Flex>
				</GridItem>
				<GridItem span={6}>
					<Flex justifyContent={{ default: 'justifyContentCenter' }}>
						<Button
							className="present-option"
							onClick={() => onViewChange('flex')}
							variant="link"
							data-testid="select-frame"
						>
							<span className="present-option-icon">
								<FontAwesomeIcon icon={faDatabase} />
							</span>
							Flexible Template
						</Button>
					</Flex>
				</GridItem> */}
				<GridItem span={6}>
					<Flex justifyContent={{ default: 'justifyContentCenter' }}>
						<Button
							className="present-option"
							onClick={() => onViewChange('copy')}
							variant="link"
							data-testid="select-copy"
						>
							<span className="present-option-icon">
								<FontAwesomeIcon icon={faCopy} />
							</span>
							Copy Existing
						</Button>
					</Flex>
				</GridItem>
			</Grid>
		</div>
	);
};

export default DefaultPresentOptionsView;
