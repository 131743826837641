import axiosConfig from '../axiosConfig';
import { TDashboardFilter } from '../dashbboards/DashboardFilter';
import { TFolder } from '../foundational-elements/Folder';
import { PresentationSlide, TPresentationSlide } from './PresentationSlide';

export type TPresent = {
	id?: number;
	description: string;
	name: string;
	owner?: number;
	folder?: TFolder | number;
	presentationSlides: TPresentationSlide[];
	presentationFilters: TDashboardFilter[];
};

export class Present implements TPresent {
	id?: number;
	description: string;
	name: string;
	owner?: number;
	folder?: TFolder | number;
	presentationSlides: TPresentationSlide[];
	presentationFilters: TDashboardFilter[];

	constructor(presentation: TPresent) {
		this.id = presentation.id;
		this.description = presentation.description;
		this.name = presentation.name;
		this.owner = presentation.owner;
		this.folder = presentation.folder;
		this.presentationSlides = presentation.presentationSlides;
		this.presentationFilters = presentation.presentationFilters;
	}

	public static Default(): TPresent {
		return {
			description: '',
			name: '',
			owner: 0,
			presentationSlides: [
				{
					page: 0,
					widgets: [],
				},
			],
			presentationFilters: [],
		};
	}

	public static New(presentation: TPresent): Promise<TPresent> {
		return axiosConfig.instance.post('app/presentation', presentation).then((res) => {
			return new Present(res.data as TPresent);
		});
	}

	public static Edit(presentation: TPresent): Promise<TPresent> {
		return axiosConfig.instance.patch(`app/presentation`, presentation).then((res) => {
			return new Present(res.data as TPresent);
		});
	}

	public static Get(presentId: number, expand: (keyof TPresent)[] = []): Promise<TPresent> {
		return axiosConfig.instance
			.get(`app/presentation/${presentId}`, { params: { expand } })
			.then((res) => {
				return new Present(res.data as TPresent);
			});
	}

	public static GetAll(expand: (keyof TPresent)[] = []): Promise<TPresent[]> {
		return axiosConfig.instance
			.get<TPresent[]>('app/presentation', { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}

	public static Delete(presentationId: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/presentation/${presentationId}`).then((res) => {
			return res.data as boolean;
		});
	}

	public static GetPresentationSlide(
		slideId: number,
		expand: (keyof TPresentationSlide)[] = []
	): Promise<PresentationSlide> {
		return axiosConfig.instance
			.get(`app/presentation/slide/${slideId}`, { params: { expand } })
			.then((res) => {
				return new PresentationSlide(res.data as TPresentationSlide);
			});
	}
}
