import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext, Outlet } from 'react-router-dom';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import { faStar, faFolder } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutletContext } from '../layout/Layout';
import { Permission } from '../enums/permission.enum';
import { FolderTypesEnum } from '../enums/folder-types-enum';
import { ZoneSettingEnum } from '../api/zone/ZoneSetting';
import { useMount } from 'react-use';
import { useApplication } from '../components/user/ApplicationProvider';

type SideBarContextItem = {
	name: string;
	icon?: JSX.Element;
	id?: string;
	to?: string;
	permission?: Permission;
	children?: SideBarContextItem[];
};

export default function Setup(): ReactElement {
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [sidebarContextItems, setSidebarContextItems] = useState<SideBarContextItem[]>([]);
	const { zoneSettings } = useApplication();

	useMount(() => {
		setupSidebarItems();
	});

	const setupSidebarItems = () => {
		const sidebarContextItems = [
			{
				name: 'Setup Getting Started',
				icon: <FontAwesomeIcon icon={faStar} />,
				to: '/setup',
			},
			{
				name: 'Analytics',
				icon: <FontAwesomeIcon icon={faFolder} />,
				id: '1',
				children: [
					{
						name: 'Dimensions',
						id: 'analytics-dimensions',
						to: '/setup/analytics/dimensions',
						permission: Permission.ViewDimension,
					},
					{
						name: 'Dimension Attributes',
						id: 'analytics-dimension-attributes',
						to: '/setup/analytics/dimension-attributes',
						permission: Permission.ViewDimensionAttribute,
					},
					{
						name: 'Key Measures',
						id: 'analytics-key-measures',
						to: '/setup/analytics/key-measures',
						permission: Permission.ViewKeyMeasure,
					},
				],
			},
			{
				name: 'Integration Tools',
				icon: <FontAwesomeIcon icon={faFolder} />,
				id: 'integration-tools',
				children: [
					{
						name: 'Dimension Sources',
						id: 'integration-tools-dimension-attribute-sources',
						to: '/setup/integration-tools/dimension-sources',
						permission: Permission.ViewDimensionSource,
					},
					{
						name: 'Key Measure Sources',
						id: 'integration-tools-key-measure-sources',
						to: '/setup/integration-tools/key-measure-sources',
						permission: Permission.ViewKeyMeasureSource,
					},
					{
						name: 'ETL Source Databases',
						id: 'integration-tools-etl-source-databases',
						to: '/setup/integration-tools/etl-source-databases',
						permission: Permission.ViewEtlSourceDatabase,
					},
					{
						name: 'Purge Key Measure Data',
						id: 'purge-key-measure-data',
						to: '/setup/integration-tools/purge-key-measures',
						permission: Permission.CanPurgeData,
					},
				],
			},
			{
				name: 'Settings',
				icon: <FontAwesomeIcon icon={faFolder} />,
				id: 'setup',
				children: [
					{
						name: 'Periods',
						id: 'setup-periods',
						to: '/setup/settings/periods',
						permission: Permission.ViewPeriod,
					},
					{
						name: 'Date Ranges',
						id: 'setup-date-ranges',
						to: '/setup/settings/date-ranges',
						permission: Permission.ViewDateRange,
					},
					{
						name: 'Unit Types',
						id: 'setup-unit-types',
						to: '/setup/settings/unit-types',
						permission: Permission.ViewUnitType,
					},
					{
						name: 'Zone Settings',
						id: 'setup-zone-settings',
						to: '/setup/settings/zone-settings',
						permission: Permission.CanViewZoneSettings,
					},
				],
			},
			{
				name: 'Security',
				icon: <FontAwesomeIcon icon={faFolder} />,
				id: 'security',
				children: [
					{
						name: 'Users',
						id: 'security-users',
						to: '/setup/security/users',
						permission: Permission.ViewUser,
					},
					{
						name: 'User Roles',
						id: 'security-roles',
						to: '/setup/security/roles',
						permission: Permission.ViewRole,
					},
					{
						name: 'User Groups',
						id: 'security-groups',
						to: '/setup/security/groups',
						permission: Permission.ViewGroup,
					},
				],
			},
			{
				name: 'System Tools',
				id: 'system-tools',
				icon: <FontAwesomeIcon icon={faFolder} />,
			},
		];

		const builderZoneSetting = zoneSettings.find(
			(setting) => setting.zone_setting_enum_id === ZoneSettingEnum.isbuilderzone
		);

		if (builderZoneSetting) {
			if (builderZoneSetting.enabled && builderZoneSetting.value.toLowerCase() == 'true') {
				sidebarContextItems.push({
					name: 'Subscription Builder',
					icon: <FontAwesomeIcon icon={faFolder} />,
					id: 'subscription-builder',
					children: [
						{
							name: 'Subscriptions',
							id: 'subscriptions',
							to: '/setup/subscription/builder',
							permission: Permission.NA,
						},
					],
				});
			}
		}

		setSidebarContextItems(sidebarContextItems);
	};

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Setup"
					pageDescription="Configure your needed infrastructure and configuration for building data artifacts and gathering insights."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
				/>
			),
			sidebarContext: sidebarContextItems,
			sidebarHeaderContext: {
				header: '',
				subheader: '',
			},
			folderType: FolderTypesEnum.Setup,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded, sidebarContextItems]);

	return (
		<React.Fragment>
			<Outlet context={{ setSubSide, subNavExpanded, setSubNavExpanded }} />
		</React.Fragment>
	);
}
