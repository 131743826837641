import React from 'react';
// import { PresentationSlide as PresentationSlideType } from '../../types/presentation';
import DropGrid from '../../components/dnd/DropGrid';
import { Widget } from '../../api/dashbboards/DashboardWidgets';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { DashboardFilter } from '../../api/dashbboards/DashboardFilter';
import { PresentationSlide as PresentationSlideType } from '../../api/present/PresentationSlide';

type Props = {
	slide: PresentationSlideType;
	handlePresentationWidgetSave: (widget: Widget) => void;
	removeWidget?: (widget: Widget) => void;
	handleEditClick?: (widget: Widget) => void;
	gridLayout?: boolean;
	filters?: DashboardFilter[];
	selectedDate?: TNewDateRange;
};

const PresentationSlide = (props: Props) => {
	const {
		slide,
		handlePresentationWidgetSave,
		removeWidget,
		handleEditClick,
		gridLayout,
		filters,
		selectedDate,
	} = props;

	return (
		<DropGrid
			widgets={slide.widgets as Widget[]}
			handleWidgetChange={handlePresentationWidgetSave}
			removeWidget={removeWidget}
			handleEditClick={handleEditClick}
			isEdit
			gridLayout={gridLayout}
			filters={filters}
			selectedDate={selectedDate}
		/>
	);
};

export default PresentationSlide;
