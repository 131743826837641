import React, { Dispatch, SetStateAction } from 'react';
import { Form, Grid, GridItem, TextInput } from '@patternfly/react-core';
import { TFolder } from '../../../api/foundational-elements/Folder';
import './DefaultDashboardOptionsView.scss';
import { TDashboard } from '../../../api/dashbboards/Dashboards';
import { DashboardCreationOptions } from './ZiDashboardOptionsModal';
import SelectFolderDropdown from '../../../helpers/helper-components/SelectFolderDropdown';

export type BlankDashboardOptionsViewProps = {
	onViewChange: Dispatch<SetStateAction<DashboardCreationOptions>>;
	dashboard: TDashboard;
	folders: TFolder[];
	handleNameChange: (value: string, event: React.FormEvent<HTMLInputElement>) => void;
	selectedFolder?: TFolder;
	onSelectFolder: Dispatch<SetStateAction<TFolder | undefined>>;
};

const BlankDashboardOptionsView = (props: BlankDashboardOptionsViewProps) => {
	const { dashboard, folders, handleNameChange, onSelectFolder, selectedFolder } = props;

	return (
		<div
			className="component-container"
			data-testid="default-view"
		>
			<Form>
				<Grid>
					<GridItem span={12}>
						<TextInput
							placeholder="Dashboard Name"
							value={dashboard.name}
							onChange={handleNameChange}
						/>
					</GridItem>
					<SelectFolderDropdown
						folderId={dashboard.folder as number}
						folders={folders}
						onFolderSelect={(e, item) => {
							onSelectFolder(folders.find((f) => f.id.toString() === item.id));
						}}
					/>
				</Grid>
			</Form>
		</div>
	);
};

export default BlankDashboardOptionsView;
