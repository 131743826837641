import { useState, useEffect } from 'react';
import { AlertVariant, Card, CardBody } from '@patternfly/react-core';
import './BuildDataframe.scss';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { Dataframe, TNewDataframe, TNewDataframeOrder } from '../../../api/dataframes/Dataframes';
import Preview, { IPreviewProps } from '../../../components/data-builder/Preview';
import { DataBuilderPropsV2, DraggableMenuItemData } from '../../../types/databuilder/databuilder';
import {
	DataframeDataRetrievalRequest,
	DataframeDataRetrievalResponse,
	GenericKeyValueResponse,
	TDataframe,
	TDataframeOrder,
	TDateRange,
} from '../../../api/types';
import { useLocation, useOutletContext, useParams } from 'react-router';
import { Folder, TFolder } from '../../../api/foundational-elements/Folder';
import { OutletContext } from '../../../layout/Layout';
import { MultipartResponse } from '../../../helpers/multipart-response.helper';
import { OptionsBuilderItemTypes } from '../../../types/dataframes/options-builder-item-types';
import { TNewDateRange } from '../../../api/types/TNewDateRange';
import { useApplication, useUser } from '../../../components/user/ApplicationProvider';
import { FolderTypesEnum } from '../../../enums/folder-types-enum';
import Loader from '../../../components/util/Loader';
import { addNewRecentDataframe } from '../../../helpers/helper-components/recents-factory-helper';
import { selectedPeriod } from '../../../helpers/selected-period.helper';
import { DateRange } from '../../../api/date-period-selector/DateRange';
import {
	buildDataframeRequest,
	getUnitTypeIdByFactId,
	populateDroppedFactsByDataframe,
	populateDroppedFiltersByDataframe,
	populateDroppedRowsByDataframe,
} from '../../../hooks/DataBuilderHooks';
import { DataBuilderTypes } from '../../../enums/data-builder-types';
import ZiDataBuilderV2 from '../../../components/data-builder/ZiDataBuilderV2';
import { useMount } from 'react-use';
import {
	DataframeDataJoin,
	TDataframeDataExistingJoin,
} from '../../../api/dataframe-data-join/DataframesDataJoin';
import { useNavigate } from 'react-router';
import { TSharedEntity } from '../../../api/shared-entity/SharedEntity';
import { DataBuilderZones } from '../../../enums/data-builder-zones';
import { Favorites, TFavorites } from '../../../api/favorites/Favorites';
import { defaultCalculatedFact } from '../../../constants/default-calculated-fact.constant';
import { FavoriteTypes } from '../../../enums/favorite-types';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageTitleSubheader from '../../../layout/subheader/PageTitleSubheader';

const BuildDataframe = () => {
	let { frameId } = useParams();
	const { currentDatePeriods, measures, dimensions, unitTypes, periods } = useApplication();
	const currentPeriod = selectedPeriod();
	const defaultPeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);

	const { setSubSide } = useOutletContext<OutletContext>();
	const [showPreview, setShowPreview] = useState<boolean>(false);
	const [previewData, setPreviewData] =
		useState<MultipartResponse<DataframeDataRetrievalResponse>>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isPreviewLoading, setPreviewIsLoading] = useState<boolean>(false);
	const [dataframe, setDataframe] = useState<TDataframe>();
	const [folders, setFolders] = useState<TFolder[]>([]);
	const [selectedFolderId, setSelectedFolderId] = useState<number>();
	const [order, setOrder] = useState<(TDataframeOrder | TNewDataframeOrder)[]>([]);
	const [favorite, setFavoriteData] = useState<TFavorites>();
	const [disablePreviewBtn, setDisablePreviewBtn] = useState<boolean>(true);
	const [selectedDate, setSelectedDate] = useState<TNewDateRange | undefined>({
		begin_date: currentPeriod.startPeriod
			? currentPeriod.startPeriod.begin_date
			: defaultPeriod.begin_date ?? 0,
		end_date: currentPeriod.endPeriod
			? currentPeriod.endPeriod.end_date
			: defaultPeriod.end_date ?? 0,
		period: currentPeriod.startPeriod
			? currentPeriod.startPeriod.period
			: defaultPeriod.period ?? 0,
		sequence: currentPeriod.startPeriod
			? currentPeriod.startPeriod.period
			: defaultPeriod.sequence ?? 0,
	});
	const [dataframeId, setDataframeId] = useState<number>();
	const { addToast } = useToast();
	const [invalidFilters, setInvalidFilters] = useState<DraggableMenuItemData[]>([]);
	const [facts, setFacts] = useState<DraggableMenuItemData[]>([]);
	const [rows, setRows] = useState<DraggableMenuItemData[]>([]);
	const [filters, setFilters] = useState<DraggableMenuItemData[]>([]);
	const [name, setName] = useState<string | undefined>('');
	const [folder, setFolder] = useState<TFolder | undefined>();

	// validation
	const [validated, setValidated] = useState<
		'default' | 'warning' | 'success' | 'error' | undefined
	>();
	const [validatedAlias, setValidatedAlias] = useState<
		'default' | 'warning' | 'success' | 'error' | undefined
	>();

	const [savedDataframeDataJoins, setSavedDataframeDataJoins] = useState<
		TDataframeDataExistingJoin[]
	>([]);
	const [deleteDataframeDataJoinIds, setDeleteDataframeDataJoinIds] = useState<number[]>([]);
	const navigate = useNavigate();
	const currentUser = useUser();
	const [operatorText, setOperatorText] = useState<string>('Select an Operation');
	const [KMF1, setKMF1] = useState<GenericKeyValueResponse<string>>({
		id: 0,
		value: 'Select Key Measure 1',
	});
	const [KMF2, setKMF2] = useState<GenericKeyValueResponse<string>>({
		id: 0,
		value: 'Select Key Measure 2',
	});
	const [calculatedFactAlias, setCalculatedFactAlias] = useState<string | undefined>();

	const handleAliasInputChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
		if (value.trim() === '') {
			setValidatedAlias('error');
		} else {
			setValidatedAlias('success');
		}

		setCalculatedFactAlias(value);
	};
	const [calculatedFact, setCalculatedFact] = useState<DraggableMenuItemData>();
	const [calculationModalOpen, setCalculationModalOpen] = useState(false);
	const [disableCalculatedFact, setDisableCalculatedFact] = useState<boolean>(false);
	const [currentCalculatedFactId, setCurrentCalculatedFactId] = useState<number>();

	const pathname = useLocation().pathname;
	const isView = pathname.includes('/view/');
	const queryParams = useLocation().search;
	const isCopy = queryParams.includes('isCopy=true');

	useMount(() => {
		const preselectedDataframeFolder = localStorage.getItem('selectedDataframeFolderId');
		if (preselectedDataframeFolder) {
			setSelectedFolderId(parseInt(preselectedDataframeFolder));
			localStorage.removeItem('selectedDataframeFolderId');
		}

		if (frameId) {
			fetchDataframeDataJoins();
		}
	});

	const fetchDataframeDataJoins = () => {
		setSavedDataframeDataJoins([]);

		setTimeout(() => {
			if (frameId) {
				void DataframeDataJoin.Get(parseInt(frameId)).then((response) => {
					setSavedDataframeDataJoins(response);
				});
			}
		}, 500);
	};

	useEffect(() => {
		setIsLoading(true);
		setSubSide({
			isLoading: isLoading,
			subheaderContext: (
				<PageTitleSubheader
					pageTitle={''}
					isCollapsable={false}
				/>
			),
		});

		if (frameId) {
			setDataframeId(+frameId);
			setValidated('success');

			//log entry into recents for loaded dataframe
			addNewRecentDataframe(frameId);
		} else {
			setValidated('error');
		}

		getPageData();
	}, []);

	// Initialize dataframe data on dateframe change
	useEffect(() => {
		const dateRangeSelector: DraggableMenuItemData = {
			id: '-1',
			entityType: OptionsBuilderItemTypes.DatePeriodSelector,
			allowedZones: [],
			data: null,
			entity: {},
			itemType: 'date',
			static: true,
		};

		if (dataframe) {
			if (!isCopy) {
				setName(dataframe.name);
			}

			setFacts(populateDroppedFactsByDataframe(dataframe, measures));
			setRows(populateDroppedRowsByDataframe(dataframe, dimensions));
			// Ensure to add the date range selector by default
			setFilters([
				...[dateRangeSelector],
				...populateDroppedFiltersByDataframe(dataframe, dimensions),
			]);
			setOrder(dataframe.order ? dataframe.order : []);
		}
	}, [dataframe]);

	useEffect(() => {
		setFolder(folders.find((_: TFolder) => _.id == selectedFolderId));
	}, [folders, selectedFolderId]);

	useEffect(() => {
		const droppedFactIds = facts
			.filter((x) => !x.data?.calculated && x.data?.id != undefined)
			.map((x) => x.data!.id) as number[];
		const dataframeFactIds =
			(dataframe?.datasets
				.filter((x) => x.keyMeasureFact != -1 && x.keyMeasureFact != null)
				.map((x) => x.keyMeasureFact) as number[]) ?? ([] as number[]);

		const unsavedKMFs = arraysAreNotEqual(droppedFactIds, dataframeFactIds);
		setDisableCalculatedFact(unsavedKMFs);
	}, [facts, dataframe]);

	const getDataframeData = () => {
		const frameIdToUse = frameId ?? dataframeId;
		if (frameIdToUse) {
			Dataframe.Get(+frameIdToUse, [
				'datasets',
				'filters',
				'rowEntry',
				'order',
				'sharedDataframe',
				'folder',
			])
				.then((response) => {
					setDataframe(response);
					setDisablePreviewBtn(false);
					if (showPreview) {
						onPreview();
					}

					if (response.folder) {
						setSelectedFolderId((response.folder as TFolder).id);
					}
				})
				.catch(() => {
					addToast('There was an issue retrieving the dataframe', AlertVariant.danger);
					setIsLoading(false);
					setSubSide({ isLoading: false });
				})
				.finally(() => {
					setIsLoading(false);
					setSubSide({ isLoading: false });
				});
		} else {
			setIsLoading(false);
			setSubSide({ isLoading: false });
		}
	};

	const getFavoriteData = () => {
		const frameIdToUse = frameId ?? dataframeId;
		if (frameIdToUse) {
			Favorites.Get(+frameIdToUse, currentUser.id)
				.then((response: TFavorites) => {
					if (response) {
						setFavoriteData(response);
					} else {
						setFavoriteData({
							user: currentUser.id,
							object_id: +frameIdToUse,
							type: FavoriteTypes.dataframe,
							name: dataframe?.name ?? '',
						});
					}
				})
				.catch(() => {
					addToast(
						'There was an issue setting the dataframe as a favorite',
						AlertVariant.danger
					);
				});
		}
	};

	const getPageData = () => {
		Folder.GetAll()
			.then((folders) => {
				if (folders && folders.length > 0) {
					const filteredFolders = folders.filter(
						(f) => f.type === FolderTypesEnum.DataFrame
					);
					setFolders(filteredFolders);

					if (dataframe && dataframe.folder) {
						setSelectedFolderId(dataframe.folder as number);
					}
				} else {
					setFolders([
						{
							id: -1,
							name: 'No folders found',
							type: 'dataframes',
							items: [],
						},
					]);
				}

				getDataframeData();
				getFavoriteData();
			})
			.catch(() => {
				addToast('There was an issue retrieving data', AlertVariant.danger);
			});
	};

	const onSave = (): void => {
		const filterValidations = filters.filter(
			(filter) =>
				filter.entityType != OptionsBuilderItemTypes.DatePeriodSelector &&
				((filter.data?.isExistingValue && !filter.data?.value) ||
					(!filter.data?.isExistingValue && !filter.data?.operator))
		);

		if (filterValidations.length > 0) {
			setInvalidFilters(filterValidations);

			addToast(
				'Please ensure that values have been set for all Dataframe filters.',
				AlertVariant.danger
			);
			return;
		}

		const request = buildDataframeRequest(
			facts,
			rows,
			[],
			filters,
			name ? name : '',
			order,
			false,
			dataframe ? true : false,
			dataframe,
			folder
		);

		setIsLoading(true);
		setInvalidFilters([]);

		if (isCopy) {
			request.id = undefined;
		}

		if (request.id) {
			Dataframe.PatchDataframe(request as TNewDataframe)
				.then((_: TDataframe): void => {
					getDataframeData();
					addToast('Dataframe updated successfully.', AlertVariant.success);
				})
				.catch((): void => {
					addToast('Error updating dataframe.', AlertVariant.danger);
					setIsLoading(false);
				});
		} else {
			Dataframe.NewDataframe(request as TNewDataframe)
				.then((response: TDataframe): void => {
					frameId = response.id.toString();

					setTimeout(() => {
						navigate(`/analyze/dataframes/edit/${frameId!}`);
					}, 500);

					setTimeout(() => {
						setDataframeId(+frameId!);
						setDisablePreviewBtn(false);
						getDataframeData();
					}, 1500);

					addToast('Dataframe created successfully.', AlertVariant.success);
				})
				.catch((): void => {
					addToast('Error creating dataframe.', AlertVariant.danger);
					setIsLoading(false);
				});
		}

		if (deleteDataframeDataJoinIds && deleteDataframeDataJoinIds.length > 0) {
			DataframeDataJoin.Delete(deleteDataframeDataJoinIds)
				.then(() => {
					fetchDataframeDataJoins();
				})
				.catch((_error) => {
					addToast(
						'An error occurred while trying to delete the Joins of Key Measures',
						AlertVariant.danger
					);
				});
		} else {
			fetchDataframeDataJoins();
		}
	};

	const onPreview = (): void => {
		if (frameId || dataframeId) {
			setPreviewIsLoading(true);
			setShowPreview(true);

			const request: DataframeDataRetrievalRequest = {
				dataframeId: frameId ? +frameId : dataframeId ? +dataframeId : 0,
				begin_date: selectedDate?.begin_date ?? 0,
				end_date: selectedDate?.end_date ?? 0,
				periodId: selectedDate?.period ?? 0,
			};

			Dataframe.Retrieve(request)
				.then((response: MultipartResponse<DataframeDataRetrievalResponse>): void => {
					setPreviewData(response);
					setPreviewIsLoading(false);
				})
				.catch((): void => {
					addToast('Error fetching preview data.', AlertVariant.danger);
					setPreviewIsLoading(false);
				});
		}
	};

	const getDataframeSharedPermissions = () => {
		let userSharedRecord: { canEdit: boolean; canShare: boolean } = {
			canEdit: false,
			canShare: false,
		};
		if (!dataframe || currentUser.id === dataframe?.owner) {
			userSharedRecord = {
				canEdit: true,
				canShare: true,
			};
		} else {
			dataframe?.sharedDataframe?.map((sc) => {
				const thisUser = (sc as TSharedEntity).shared_entity_users?.find(
					(u) => u.user === currentUser.id
				);
				const thisGroup = (sc as TSharedEntity).shared_entity_groups?.find(
					(u) => currentUser.groups.findIndex((ug) => ug === u.group) > -1
				);
				if (thisUser) {
					userSharedRecord = { canEdit: thisUser.can_edit, canShare: thisUser.can_share };
				} else if (thisGroup) {
					userSharedRecord = {
						canEdit: thisGroup.can_edit,
						canShare: thisGroup.can_share,
					};
				}
			});
		}

		return userSharedRecord;
	};

	function arraysAreNotEqual(arr1: number[], arr2: number[]): boolean {
		// Check if arrays have the same length
		if (arr1.length !== arr2.length) {
			return true;
		}

		// Check if all corresponding items are equal
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) {
				return true;
			}
		}

		return false;
	}

	const saveCalculatedKMF = () => {
		const KmfId1 = dataframe?.datasets.find((x) => x.keyMeasureFact == KMF1.id)?.id;
		const kmfId2 = dataframe?.datasets.find((x) => x.keyMeasureFact == KMF2.id)?.id;
		const calculatedFact = dataframe?.datasets.find(
			(x) => x.calculated && x.id == currentCalculatedFactId
		);

		if (calculatedFactAlias && calculatedFactAlias.trim() == '') {
			setValidatedAlias('error');
			return;
		}

		if (!KmfId1 && !kmfId2) {
			return;
		}

		const calculatedKMF: DraggableMenuItemData = {
			id: '-1',
			entity: {},
			entityType: OptionsBuilderItemTypes.CalculatedFact,
			itemType: 'dropdown',
			allowedZones: [DataBuilderZones.facts],
			static: false,
			data: {
				id: undefined,
				title: calculatedFact?.title ?? calculatedFactAlias ?? '',
				totaled: false,
				alias: calculatedFactAlias,
				formula_operation: operatorText,
				formula_variable_1: KMF1.id,
				formula_variable_2: KMF2.id,
				calculated: true,
				numDecimals: 2,
				unitType: getUnitTypeIdByFactId(measures, KMF1.id),
			},
		};

		facts.map((fact) => {
			if (
				fact.data &&
				(fact.data?.id == undefined ||
					fact.data?.id == null ||
					fact.data.id == currentCalculatedFactId) &&
				(fact.data.title == defaultCalculatedFact.display_name ||
					fact.data.title == calculatedFact?.title)
			) {
				fact.data = calculatedKMF.data;
			}
		});

		setCalculatedFact(calculatedKMF);
		setCalculatedFactAlias(calculatedFactAlias);

		setFacts(facts);
		setCalculationModalOpen(false);
	};

	const builderProps: DataBuilderPropsV2 = {
		dataframe: dataframe,
		formLabel: 'Dataframe Name',
		onSave,
		onPreview,
		isEdit: dataframe ? true : false,
		isView,
		measures,
		dimensions,
		unitTypes,
		periods,
		folders,
		selectedDate,
		setSelectedDate,
		invalidFilters,
		facts,
		rows,
		filters,
		setFacts,
		setRows,
		setFilters,
		name: name ?? '',
		setName,
		folder,
		setFolder,
		sidebarHeader: 'Add Data',
		sidebarSubheader: 'Drag & drop data to add it to your dataframe',
		validated,
		setValidated,
		type: DataBuilderTypes.dataframe,
		disablePreviewBtn,
		savedDataframeDataJoins,
		setSavedDataframeDataJoins,
		setDeleteDataframeDataJoinIds,
		fetchDataframeDataJoins,
		order,
		setDataframeId,
		setDisablePreviewBtn,
		getDataframeData,
		sharedPermissions: getDataframeSharedPermissions(),
		saveCalculatedKMF,
		operatorText,
		setOperatorText,
		KMF1,
		setKMF1,
		KMF2,
		setKMF2,
		calculationModalOpen,
		setCalculationModalOpen,
		calculatedFactAlias,
		setCalculatedFactAlias,
		handleAliasInputChange,
		validatedAlias,
		setValidatedAlias,
		disableCalculatedFact,
		previewData,
		showLimitWarning: previewData?.json?.result_size?.limitReached,
		setCurrentCalculatedFactId,
		favorite: favorite,
		setFavorite: getFavoriteData,
		calculatedFact,
		hideFolderSelection: isView,
	};

	const previewProps: IPreviewProps = {
		data: previewData,
		facts,
		rows,
		columns: [],
		unitTypes,
		isLoading: isPreviewLoading,
		setIsLoading: setPreviewIsLoading,
		order: order ?? undefined,
		setOrder,
	};

	return (
		<Card className="dataframe-container">
			<CardBody>
				{isLoading ? (
					<Loader />
				) : (
					<>
						{isCopy && (
							<>
								<div className="pf-c-alert pf-m-inline pf-m-info">
									<p className="pf-c-alert__title display-inline">
										<FontAwesomeIcon
											size="2x"
											icon={faInfoCircle}
											className="bolder"
										/>
										<span className="nudge-right"></span>
										<div className="fs-large">
											{`Note! You are currently creating a copy of the dataframe '${
												dataframe?.name ?? ''
											}'`}
										</div>
									</p>
								</div>
								<br />
							</>
						)}
						<ZiDataBuilderV2 {...builderProps} />
					</>
				)}
				{showPreview && !isLoading && <Preview {...previewProps} />}
			</CardBody>
		</Card>
	);
};

export default BuildDataframe;
