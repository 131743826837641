import React, { Dispatch, useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	Grid,
	GridItem,
	Modal,
	ModalVariant,
	Select,
	SelectDirection,
	SelectOption,
	SelectOptionObject,
	SelectVariant,
	TextInput,
	TimePicker,
} from '@patternfly/react-core';
import { IConfig } from '../../../layout/Layout';
import Loader from '../../util/Loader';
import { useNavigate } from 'react-router';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';

export type AddEditScheduleModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onSetSubSide: Dispatch<React.SetStateAction<IConfig>>;
};

function AddEditScheduleModal(props: AddEditScheduleModalProps) {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { addToast } = useToast();
	const [isScheduleDropdownOpen, setIsScheduleDropdownOpen] = React.useState<boolean>(false);
	const [isPeriodDropdownOpen, setIsPeriodDropdownOpen] = React.useState<boolean>(false);
	const [isPeriodTypeDropdownOpen, setIsPeriodTypeDropdownOpen] = React.useState<boolean>(false);
	const [scheduleDescription, setScheduleDescription] = React.useState<string>();
	const [descriptionError, setDescriptionError] = useState(false);
	const [time, setTime] = useState<string>('');
	const [timeError, setTimeError] = useState(false);
	const [showWeeklyConfig, setShowWeeklyConfig] = useState(false);
	const [showMonthlyConfig, setShowMonthlyConfig] = useState(false);
	const [showYearlyConfig, setShowYearlyConfig] = useState(false);
	const [showAdvancedConfig, setShowAdvancedConfig] = useState(false);
	const [currentSelectedPeriod, setCurrentSelectedPeriod] = useState<
		SelectOptionObject | undefined
	>();

	const days = [
		{ label: 'Sunday', value: 'sunday' },
		{ label: 'Monday', value: 'monday' },
		{ label: 'Tuesday', value: 'tuesday' },
		{ label: 'Wednesday', value: 'wednesday' },
		{ label: 'Thursday', value: 'thursday' },
		{ label: 'Friday', value: 'friday' },
		{ label: 'Saturday', value: 'saturday' },
	];

	const months = [
		{ value: 'january', label: 'January' },
		{ value: 'february', label: 'February' },
		{ value: 'march', label: 'March' },
		{ value: 'april', label: 'April' },
		{ value: 'may', label: 'May' },
		{ value: 'june', label: 'June' },
		{ value: 'july', label: 'July' },
		{ value: 'august', label: 'August' },
		{ value: 'september', label: 'September' },
		{ value: 'october', label: 'October' },
		{ value: 'november', label: 'November' },
		{ value: 'december', label: 'December' },
	];

	useEffect(() => {
		resetFormState();
	}, [props.isOpen]);

	const resetFormState = () => {
		setDescriptionError(false);
		setTimeError(false);
		setCurrentSelectedPeriod(undefined);

		setShowWeeklyConfig(false);
		setShowMonthlyConfig(false);
		setShowYearlyConfig(false);
		setShowAdvancedConfig(false);
	};

	const handleDropdownToggle = (
		isOpen: boolean,
		selection: string | SelectOptionObject | undefined
	) => {
		setIsScheduleDropdownOpen(isOpen);

		if (selection) {
			setCurrentSelectedPeriod(selection);

			if (selection == 'weekly') {
				setShowWeeklyConfig(true);
			} else {
				setShowWeeklyConfig(false);
			}

			if (selection == 'monthly') {
				setShowMonthlyConfig(true);
			} else {
				setShowMonthlyConfig(false);
			}

			if (selection == 'advanced') {
				setShowAdvancedConfig(true);
			} else {
				setShowAdvancedConfig(false);
			}

			if (selection == 'yearly') {
				setShowYearlyConfig(true);
			} else {
				setShowYearlyConfig(false);
			}
		}
	};

	const handlePeriodDropdownToggle = (isOpen: boolean) => {
		setIsPeriodDropdownOpen(isOpen);
	};

	const handlePeriodTypeDropdownToggle = (isOpen: boolean) => {
		setIsPeriodTypeDropdownOpen(isOpen);
	};

	const handleDescriptionChange = (value: string, event: React.FormEvent<HTMLInputElement>) => {
		setScheduleDescription(value);
		setDescriptionError(value.trim() === '');
	};

	const handleTimeChange = (newTime: string, isValid: boolean | undefined) => {
		setTime(newTime);
		setTimeError(newTime.trim() === '');
	};

	const saveReportSchedule = () => {
		if (!scheduleDescription) {
			setDescriptionError(true);
		}

		if (!time) {
			setTimeError(true);
		}
	};

	const daysCheckboxHtml = (
		<>
			<div className="every-day-container">
				<Checkbox
					key={'every-day-month'}
					label={'Every Day of Month'}
					id={'every-day-month'}
					name="every-day-month"
				/>
			</div>
			<div className="checkbox-grid">
				{(() => {
					const days = [];
					for (let i = 1; i <= 31; i++) {
						days.push(
							<Checkbox
								key={i}
								label={i.toString()}
								id={`day-${i}`}
								name="day"
							/>
						);
					}
					days.push(
						<Checkbox
							key={'day-last'}
							label={'Last Day'}
							id={`day-last`}
							name="day-last"
						/>
					);
					return days;
				})()}
			</div>
		</>
	);

	const weeklyCheckboxHtml = (
		<>
			<div className="every-day-container">
				<Checkbox
					key={'every-day'}
					label={'Every Day of Week'}
					id={'every-day'}
					name="day"
				/>
			</div>
			<div className="checkbox-grid">
				{days.map((day) => (
					<Checkbox
						key={day.value}
						label={day.label}
						id={day.value}
						name="day"
					/>
				))}
			</div>
			<br />
			<br />
		</>
	);

	const monthlyCheckboxHtml = (
		<>
			{
				<>
					<div className="every-day-container">
						<Checkbox
							key={'every-month'}
							label={'Every Month'}
							id={'every-month'}
							name="month"
						/>
					</div>
					<div className="checkbox-grid">
						{months.map((day) => (
							<Checkbox
								key={day.value}
								label={day.label}
								id={day.value}
								name="day"
							/>
						))}
					</div>
				</>
			}
			<br />
		</>
	);

	const yearlyCheckboxHtml = [
		monthlyCheckboxHtml,
		<>
			{daysCheckboxHtml} <br />
		</>,
	];

	const advancedCheckboxHtml = [
		weeklyCheckboxHtml,
		<>
			{monthlyCheckboxHtml}
			<br />
		</>,
		<>
			{daysCheckboxHtml} <br />
		</>,
	];

	return (
		<Modal
			title={'Create New Report Schedule'}
			variant={ModalVariant.medium}
			isOpen={props.isOpen}
			onClose={props.onClose}
			actions={[
				<Button
					data-testid="create-button"
					key="create"
					variant="primary"
					onClick={() => {
						saveReportSchedule();
					}}
				>
					Create
				</Button>,
				<Button
					data-testid="cancel-button"
					key="cancel"
					variant="link"
					onClick={props.onClose}
				>
					Cancel
				</Button>,
			]}
		>
			<div>
				{isLoading ? (
					<Loader />
				) : (
					<div>
						<br />
						<div className="inline-label-input">
							<label
								htmlFor={'schedule-description'}
								className="input-label"
							>
								Description:<span className="pf-m-danger">*</span>
							</label>
							<TextInput
								className={`form-control ptb-10 ${descriptionError ? 'error' : ''}`}
								value={scheduleDescription}
								id="schedule-description"
								onChange={handleDescriptionChange}
								validated={descriptionError ? 'error' : 'default'}
								isRequired
							/>
							{descriptionError && (
								<small className="error-message">This field is required</small>
							)}
						</div>
						<Grid span={12}>
							<GridItem span={2}>
								<label
									htmlFor={'time-picker'}
									className="input-label"
								>
									Run at: <span className="pf-m-danger">*</span>
								</label>
							</GridItem>
							<GridItem span={5}>
								<TimePicker
									id={'time-picker'}
									name={'tdpRunAt'}
									className={timeError ? 'error-required' : ''}
									value={time}
									onChange={(e, time, hour, minute, seconds, isValid) => {
										handleTimeChange(time, isValid);
									}}
									menuAppendTo={'parent'}
								/>

								{timeError && (
									<>
										<br />
										<small className="error-message time-error">
											This field is required
										</small>
									</>
								)}
							</GridItem>
						</Grid>
						<br />
						<Grid span={12}>
							<GridItem span={2}>
								<label
									htmlFor={'time-picker'}
									className="input-label"
								>
									Schedule: <span className="pf-m-danger">*</span>
								</label>
							</GridItem>
							<GridItem span={3}>
								<Select
									variant={SelectVariant.single}
									onToggle={(isOpen) => handleDropdownToggle(isOpen, undefined)}
									onSelect={(event, selection) =>
										handleDropdownToggle(false, selection)
									}
									selections={currentSelectedPeriod ?? []}
									isOpen={isScheduleDropdownOpen}
									isDisabled={false}
									direction={SelectDirection.down}
									menuAppendTo={() => document.body}
								>
									{[
										<SelectOption
											value={'daily'}
											key={'daily'}
										>
											Daily
										</SelectOption>,
										<SelectOption
											value={'weekly'}
											key={'weekly'}
										>
											Weekly
										</SelectOption>,
										<SelectOption
											value={'monthly'}
											key={'monthly'}
										>
											Monthly
										</SelectOption>,
										<SelectOption
											value={'yearly'}
											key={'yearly'}
										>
											Yearly
										</SelectOption>,
										<SelectOption
											value={'quarterly'}
											key={'quarterly'}
										>
											Quarterly
										</SelectOption>,
										<SelectOption
											value={'advanced'}
											key={'advanced'}
										>
											Advanced
										</SelectOption>,
									]}
								</Select>
							</GridItem>
							{showAdvancedConfig && (
								<>
									<GridItem span={2}></GridItem>
									<GridItem
										span={5}
										style={{ display: 'inline-flex' }}
									>
										<label
											style={{ width: '144px' }}
											htmlFor={'cron-string'}
											className="input-label"
										>
											Cron String:
										</label>
										<TextInput
											className="form-control"
											value={scheduleDescription}
											id="cron-string"
										/>
									</GridItem>
								</>
							)}
						</Grid>
						<br />
						{showWeeklyConfig && weeklyCheckboxHtml}
						{showMonthlyConfig && monthlyCheckboxHtml}
						{showYearlyConfig && yearlyCheckboxHtml}
						{showAdvancedConfig && advancedCheckboxHtml}
						<Grid span={12}>
							<GridItem span={2}>
								<label
									htmlFor={'time-picker'}
									className="input-label"
								>
									Report Period: <span className="pf-m-danger">*</span>
								</label>
							</GridItem>
							<GridItem span={3}>
								<Select
									variant={SelectVariant.single}
									onToggle={(isOpen) => handlePeriodDropdownToggle(isOpen)}
									onSelect={(event, selection) =>
										handlePeriodDropdownToggle(false)
									}
									selections={[]}
									isOpen={isPeriodDropdownOpen}
									isDisabled={false}
									direction={SelectDirection.down}
									menuAppendTo={() => document.body}
								>
									{[
										<SelectOption
											value={'day'}
											key={'day'}
										>
											Day
										</SelectOption>,
										<SelectOption
											value={'week'}
											key={'week'}
										>
											Week
										</SelectOption>,
										<SelectOption
											value={'month'}
											key={'month'}
										>
											Month
										</SelectOption>,
										<SelectOption
											value={'year'}
											key={'year'}
										>
											Year
										</SelectOption>,
										<SelectOption
											value={'quarter'}
											key={'quarter'}
										>
											Quarter
										</SelectOption>,
									]}
								</Select>
							</GridItem>
							<GridItem span={3}>
								<Select
									variant={SelectVariant.single}
									onToggle={(isOpen) => handlePeriodTypeDropdownToggle(isOpen)}
									onSelect={(event, selection) =>
										handlePeriodTypeDropdownToggle(false)
									}
									selections={[]}
									isOpen={isPeriodTypeDropdownOpen}
									isDisabled={false}
									direction={SelectDirection.down}
									menuAppendTo={() => document.body}
								>
									{[
										<SelectOption
											value={'current'}
											key={'current'}
										>
											Current
										</SelectOption>,
										<SelectOption
											value={'previous'}
											key={'previous'}
										>
											Previous
										</SelectOption>,
									]}
								</Select>
							</GridItem>
						</Grid>
					</div>
				)}
			</div>
		</Modal>
	);
}

export default AddEditScheduleModal;
